.AmountBetweenFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 789px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
    }

    label {
        display: flex;
        align-items: center;
        margin-right: 8px;
        justify-content: space-between;
        width: 50%;

        @media screen and (max-width: 789px) {
            justify-content: space-between;
            align-items: baseline;
            width: 100%;
            margin-bottom: 16px;
        }
        &:last-child {
            margin-right: 0;
        }

        input {
            width: 100px;
        }

        span {
            margin-right: 6px;
        }
    }
}