.Pagination {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 0.75rem;
    @media screen and (max-width: 789px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-container {
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 789px) {
            margin-bottom: 16px;
        }
    }

    select {
        height: 24px;
    }

    &-gotToPageContainer,
    &-selectContainer {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
    }

    &-gotToPageContainer {
        input {
            margin-right: 6px;
            width: 50px;
        }
    }

    &-gotToButtons {
        display: flex;
        margin-right: 16px;
        height: 25px;
        span {
            width: 1px;
            background-color: rgba(0, 0, 0, 0.16);
        }
        button {
            border: none;
            background-color: inherit;
            cursor: pointer;
        }
    }

    &-download {
        position: relative;

        @media screen and (max-width: 789px) {
            margin-bottom: 16px;
        }
        &--button {
            background-color: #23558C;
            color: white;
            border-radius: 4px;
            padding: 0.4rem 3rem;
            border: none;
            position: relative;
            cursor: pointer;
            svg {
                position: absolute;
                right: 1rem;
                g {
                    g{
                        fill: #fff;
                    }
                }
            }

            &::after {
                width: 0;
                height: 0;
                border: 150px solid transparent;
                border-top: 0;
                border-bottom: 300px solid red;
            }
        }
        &--btnContainer {
            position: absolute;
            top: -100px;
            width: 200px;
            left: -25px;
            background-color: #fff;
            padding: 12px;
            box-shadow: 0 0.5em 1em -0.125em grey;
            border-radius: 6px;

            button {
                background-color: #23558C;
                color: white;
                border-radius: 4px;
                padding: 0.3rem 0;
                width: 100%;
                border: none;
                cursor: pointer;
                &.downloadAll {
                    margin-bottom: 12px;
                }
            }
            

            .arrow-down {
                position: absolute;
                left: 40%;
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #fff;
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
            cursor: not-allowed;
        }
    }
}