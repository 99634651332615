.globalFilter {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 6px;

    input {
        height: 26px;
        width: 100%;
    }
}