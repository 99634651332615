.comments-page {

  $avatar-size: 60px;
  padding: 0 1rem 1rem 1rem;
  background: #ffffff;

  .no-messages {
    margin-top: 15px;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .portal-button {
      margin:15px 0;
    }
  }


  .new-message-divider {
    color: $label-color;
    border-bottom: 1px solid $label-color;
    width: 100%;
  }

  .new-message-divider {
    margin-top: 10px;
    color: $label-color;
    font-size: 0.825rem;
    position: relative;
    background: white;
    span {
      position: absolute;
      left:50%;
      transform: translateY(-50%) translateX(-50%);
      background: $white;
      padding: 0 10px;
    }
  }

  .contact-messages-layout {
    display: flex;
    flex-direction: column;

    .contact-container {
      margin-left: auto;
    }

    .contact-message-container {
      display: flex;
      align-items: flex-start;
      margin: 15px 0;
      @include touch {
        margin-left: 10px;
      }

      .contact-message-header-mobile{
        @include touch {
          display: none;
        }
      }

      .contact-message-header {

        .message-avatar {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          &.dark {
            align-items: flex-start;
          }
        }

        .message-buttons {
          margin-top: 1rem;
          display: flex;
          justify-content: flex-start;

          .re-open, .delete-message {
            color: $red;
            border-color: $red;

            svg {
              * {
                fill: $red;
              }
            }

            &:hover {
              color: $white;
              background: $red;

              svg {
                * {
                  fill: $white;
                }
              }

            }
          }
        }
      }

    }

    .contact-messages {
      @include touch{
        align-items: center;
      }
      display: flex;
      flex-direction: column;

      .contact-message {
        flex:1;
        background: #F2F7FA;
        padding: 1rem;
        border-radius: 1.25rem;
        border: 1px solid #D6D6D6;
        position: relative;
        overflow-wrap: break-word;
        max-width: 35rem;

        &-content {
          white-space: break-spaces;
        }
        &.dark {
          background: #EBEBEB ;
          align-self: normal;
          color: #212121;
          border: 1px solid #D3DDE8;
          
          @include touch {
            min-width: 9rem;
          }
        }

        &-header {
          font-size: 0.825rem;
          width:180px;
          position: relative;
          &.dark {
            left:40px;
          }
          &:not(.dark) {
            right: 40px;
          }

          &-sender {
            color: #212121;
            font-family: "Open Sans Semibold";
          }

          &-contact {
            color: $color1;
          }

          &-details {
            color: $label-color;
          }

          .kb-avatar {
            width: 78px;
            height: 78px;
            border-radius: 50%;
            border: 1px solid #D6D6D6;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 56px;
              height: 56px;
            }
          }
        }

        &-corner {
          $corner-size: 0.5 * $avatar-size;
          height: $corner-size;
          width: $corner-size;
          overflow: hidden;
          background: #F2F7FA;
          position: absolute;
          top: 12px;

          &.dark {
            transform: rotate(90deg);
            border-left: 1px solid #EBEBEB;
            background: #EBEBEB;
            right: -$corner-size;
          }

          &:not(.dark) {
            background: #F2F7FA;
            border-bottom: 1px solid #F2F7FA;
            transform: rotate(180deg);
            left: -$corner-size;
          }

          .corner-inner {
            width: 2 * $corner-size;
            height: 2 * $corner-size;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            background: white;
            border: 1px solid #F2F7FA;

          }
        }
      }

      .underwriter-info {
        width: 100%;
        margin-top: 20px;
        padding: 20px;
        border: 1px solid #D6D6D6;
        background-color: #F5F5F5;
        border-radius: 14px;

        &--title {
          font-family: "Open Sans Semibold";
          margin-bottom: 1rem;
        }

        .group-members {
          font-size: 0.75rem;
        }

      }

    }

    .contact-message-editor {
      @include touch {
        display: block;
      }
      .contact-message-header:last-child {
        left:40px;
      }

      .sign {
        font-size: 12px;
        display: flex;
        align-items: center;


        .signature-hint {
          text-align: right;
          font-weight: $weight-semibold;

          > :first-child {
            color: $label-color;
          }

          > :last-child {
            cursor: pointer;
          }
        }

        > :not(:first-child) {
          margin-left: 0.5rem;
          @include touch {
            min-width: 8.125rem;
          }
        }
      }

      .message-buttons {
        display: flex;
        justify-content: flex-end;
      }

      &.waiting-for-sign {
        .contact-message, .contact-message-corner {
          background: $highlighter-color;
          @include touch {
            min-width: 290px;
          }
        }
      }

      .inputs {

        textarea {
          height: 10rem;
          resize: vertical;
        }
      }

      .img-button {
        flex-shrink: 0;
        align-self: flex-end;
        @include touch {
          width: 45px;
          height: 45px;
        }
      }
    }

  }

}

.common-upload-component {
  @include desktop {
    width: 650px;
  }
  margin: 10px auto;
  
  .uploaded-document {
    padding: 10px;
  }
}

.comment-documents-component {
  z-index: 0;

  .upload-component {
    margin-bottom: 10px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed #D6D6D6;

    @include desktop {
      padding: 20px 10px;
    }

    @include touch {
      padding-top: 1rem
    }

    .dnd-message {
      font-size: 12px;
      color: #5C5C5C;
    }

    button {
      width: 190px;
      margin: auto;
    }

    .limits {
      font-size: 12px;
      color: #5C5C5C;
      text-align: center;

      @include touch {
        word-break: break-word;
        padding-bottom: 1rem;
        font-size: x-small;
      }
    }

    > :not(:first-child) {
      margin-top: 6px;
    }
  }

  .comment-documents {
    background: white;

    .comment-document {
      padding: 10px;
      background: white;
      border-radius: 4px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 0, rgba(0, 0, 0, 0) 25px);

      &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .document-file-name {
          display: flex;
          align-items: center;
          font-size: 0.825rem;

          > span {
            margin-left: 0.5rem;
          }
        }

        button {
          color: #ADADAD;

          svg * {
            fill: #ADADAD;
          }

          &:hover {
            background: #ADADAD !important;
            color: $white !important;

            svg * {
              fill: $white !important;
            }
          }
        }
      }


      &--description {
        label {
          font-size: 11px;
        }

        textarea {
          resize: vertical;
          height: initial !important;
        }
      }
    }
  }
}

.documents-table {
 
  tr td {
    vertical-align: middle;
  }
  
  a {
    color: $label-color;
  }
  
  .icon {
    margin-right: 6px;
    height: 16px;
    
    &.is-grey g[id$="-icon-fill"] {
      fill: $label-color;
    }
  }
  
  .description {
    width: 45%;
  }
  
  .download {
    width: 100px;
  }
}

