.pointer {
    cursor: pointer;
}

.drawingAmount {
    font-weight: 700;
    color: #212121;
}

.breakLine {
    line-break: anywhere;
}

.dueDate {
    display: flex;
    position: relative;

    &-tooltip {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        margin-top: -5px;
        margin-bottom: auto;
        -webkit-transform: translate(-50%,-100%);
        background: #0a0a0a;
        color: #fff;
        max-height: 400px;
        max-width: 350px;
        width: 350px;
        padding: 0.5rem;
        white-space: pre-wrap;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 14px;
        opacity: .8;
        z-index: 1000;
        text-align: center;
    }

    svg {
        margin-left: 4px;
        width: 16px;
        height: 16px;
    }
}
.cell {
    &-container {
        &--event,
        &--state {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    &-border {
        border: 1px solid #000;
        border-radius: 20px;
        padding: 0 4px;
        text-align: center;
        display: initial;
        font-size: 12px;
        font-weight: 600;
    }
}

.blue {
    color: #23558C;
    border-color: #23558C;
}
