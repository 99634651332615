@import "../../node_modules/bulma/sass/utilities/initial-variables";

$font-family-regular: 'Open Sans';
$font-family-bold: 'Open Sans Bold';
$font-family-extra-bold: 'Open Sans Extrabold';
//
//$color1: #291559;
//$color2: #E8E7ED;
//$color3: #05aea2;
//$color4: #abeae0;
//$color5: #1b3152;
//$color6: #f6f8fd;
//$color7: #00dbb9;
//$color8: #95f8e9;

$color1:#23558C;
$color2:#D7D7D7;
$color3:#00A3E0;
$color4:#FDFDFD;
$color5:#28D1AD;
$color6:#B0F4E5;
$color7:#F97A94;
$color8:#FBA6B7;
$color9:#134069;
$color10:#FEE9ED;
$color11:#E6F5FC;
$color12: #E8E7ED;
$color13:#35BD5B;
$color14:#EFFAFF;
$color15:#F5F5F5;
$color16:#7DACBE;
$color17:#92A1C4;
$color-disabled:$color12;
$color18:#334368;
$color19:#cce8f3;
$color20:#00BAFF;
$color21:#00D0F2;

$size-2: 2.675rem;
$size-4: 1.75rem;
$inverseColor: $white;

$primary: $color1;
$primary-invert: $white;
$body-size: 0.875rem;
$warning: $color7;
$danger: #E00000;
$red: #E00000;
$yellow:#F5A623;

$header-color: $white;
$menu-color: #212121;
$active-color:$color3;
$icon-color:$primary;
$highlighter-color: #FDEDD3;

$greyText:#979797;

$field-max-width:550px;

// Use the new serif family
$family-primary: $font-family-regular;

$border-color: $primary;

$label-color: #757575;
$input-color: #212121;
$input-border-color: #D6D6D6;
$input-hover-border-color: #D6D6D6;
$input-focus-border-color: #ADADAD;
$input-focus-box-shadow-size: inset 0 0 2px;
$input-focus-box-shadow-color: rgba(0, 0, 0, 0.16);
$input-shadow: inset 0 0 2px rgba(0, 0, 0, 0.16);

$input-disabled-color: #212121;
$input-disabled-background-color: #EBEBEB;
$input-disabled-border-color: #D6D6D6;

$slider-thumb-background: $color2;
$slider-track-background: transparent;
$slider-thumb-border: 0px;
$offset: 0px;
$offset-mobile: 0px;
$offset-tablet: 0px;
$screen-width: 960px !important;
$container-padding: 1rem;
$controls-background: $white;
$footer-color: $white;
$header-height: 80px;
$portal-header-height: 80px;
$footer-height: 80px;
$min-page-height:500px;

//$steps-default-color: $grey-lighter;
$steps-active-color: $color1;
$steps-divider-size: 0.1em;
//$steps-completed-color: $primary;

$tabs-link-color: #212121;
$tabs-link-active-color: $white;
$tabs-link-active-border-bottom-color: #212121;
$tabs-border-bottom-color:#212121;
$tabs-border-bottom-width: 0 !important;
$modal-background-background-color: rgba(0, 0, 0, 0.32) !default

$tabs-boxed-link-active-border-color: #212121 !important;
$tabs-boxed-link-active-background-color: #212121 !important;
$tabs-boxed-link-hover-border-bottom-color: #212121;
$tabs-boxed-link-hover-background-color: $white;

$table-head-cell-color: $white;
$table-head-background-color: #757575;
$table-cell-border-width: 0 1px;
$table-head-cell-border-width: 0 1px 1px;
$table-color: #757575;
$table-cell-border: 1px solid rgba(0, 0, 0, 0.16);
$table-cell-padding: 0.125rem 1rem;
$table-striped-row-even-background-color:#F5F5F5;

$modal-card-body-padding: 12px;
$modal-card-head-padding: 12px;
$modal-card-head-background-color: $white;
$modal-card-body-background-color: #F5F5F5;
$modal-card-title-color: $color1;
$modal-card-title-size: 1.5rem;
$modal-card-head-border-bottom: 0;
$modal-card-foot-border-top: 0;

$box-padding: 1rem;

$button-focus-color: #23558C;
$button-focus-border-color: #23558C;
$button-focus-box-shadow-size:0;

$navbar-item-hover-background-color:$white;
$navbar-burger-color: $red;
$tablet:768px;

$notification-padding: 5px 10px;
$success: #35BD5B;

$title-color: #212121;
$title-size: 1rem;
$title-weight: normal;

$subtitle-color: $color1;
$subtitle-size: 0.875rem;
$subtitle-weight: normal;

$radius-small: 4px;