

.page {
  padding-bottom: 1rem;
}

.portal-table.table {
  font-size: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  border-collapse: separate;
  border-radius: 4px;
  
  thead {
    background-color: #757575;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
  
    tr {
      height: 38px;
    }
  }
  
  tbody tr:last-child td {
    &:first-child {
      border-radius: 0 0 0 4px;
    }
  
    &:last-child {
      border-radius: 0 0 4px 0;
    }
  }
  
  tr {
    
    &.new-value-setter {
      background-color: $highlighter-color;
    }
    
    &.has-limit-error {
      background-color: #F7D9E0 !important;
    }
  
    th {
      font-weight: normal;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
    
      &:first-child {
        border-radius: 4px 0 0 0;
      }
    
      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
    
    td {
      color: $input-color;
      
      &.category-name {
        font-family: "Open Sans Semibold";
      }
      
      input.has-error {
        border: 1px solid $danger !important;
      }
    }
  
    th, td {
      vertical-align: middle;
      height: 2rem;
    
      &.is-checkbox {
        width: 70px;
      }
    }
  }
  
  .action-button {
    display: flex;
    align-items: center;
    color: $label-color;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 3px 0 6px;
    
    svg {
      margin-right: 6px;
      max-width: 20px;
      max-height: 20px;
    }
    
    svg g[id$="-icon-fill"] {
      fill: #ADADAD;
    }
    
    &:hover {
      color: $color1;
  
      svg g[id$="-icon-fill"] {
        fill: currentColor;
      }
    }
  }
}

.portal-icon {
  cursor: pointer;
}

.portal-buttons {
  
  &.has-right-align {
    float: right;
  }
  
  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &.disabled {
    pointer-events: none;
  }

}

.portal-button.is-outlined {
  @include touch {
    margin-bottom: 0.75rem;
  }

  min-height: 32px;
  min-width: 60px;
  font-family: $font-family-bold;
  font-size: 0.75rem;
  color: $color1;
  border: 1px solid $color1;
  padding: 0 0.5em;

  .portal-button-label{
    white-space: nowrap;
  }
  
  svg {
    margin-right: 0.5em;
    max-height: 20px;
    max-width: 25px;

    * {
      fill:currentColor;
    }
  }

  
  &:hover {
    &:not(.is-success):not(.is-danger) {
      background-color: $color1;
      color: $white;
    }

  }

  &:disabled {
    color: #ADADAD !important;
    background: #EBEBEB !important;
    border-color: #ADADAD !important;
    cursor: default !important;
  
    svg g[id$="-icon-fill"] { fill: #ADADAD !important; }
  }

  &:focus {
    outline: none;
  }

  a {
    color: inherit;
  }

  &.transparent {
    background-color: inherit;
    border: 0;
  }

  &.is-large {
    font-size: 1.5rem;
  }

  &.is-borderless {
    border: none;
  }

}


.errors {
  display: flex;
  font-family: $font-family-bold;
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
  color: $danger;
  margin: 0.75rem 0;
  padding: 5px 10px;
}

.activation-page {
  .login-container {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 30%;
    width: 100%;
    .login-form {
      width: 300px;
    }
    .login-button-container {
      display: flex;
      justify-content: center;
    }
  }
}

.show-hide-password {
  cursor: pointer;
  pointer-events: all;
  color: $color1;
  margin-right: 10px;

  &.hide-password {
    * {
      //fill:$color-disabled
    }
  }
}

.loan-conditions {
  padding: 2rem;
  ul {
    list-style: disc;
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-family-bold;
  font-size: $size-large;
  color:$color17;
  .msg {
    padding: 1rem 1rem;
    text-align: center;
  }
}

.portal {
  padding-top: 10px;
  
  .buttons {
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    
    &.has-right-align {
      justify-content: flex-end;
    }
  }

  .wizard {
    .buttons {
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }


  .tabs {
    font-family: "Open Sans Bold";
    position: relative;
    margin-bottom: 0 !important;

    display: flex;
    flex-flow: column;
    
    ul li {
      a {
        border: 0;
        background-color: $white;
        color: #ADADAD;
        font-size: 0.8rem;
        height: 40px;
        margin-right: 0.5rem;
        padding: 0.5rem 2rem;
        
        &:hover {
          background-color: #ADADAD;
          color: $white;
        }
      }
      
      &.is-active a {
        color: $white;
      }
      
      &.has-link-style {
        @include desktop {
          position: absolute;
        }
        &.history-tab{
          @include desktop {
            right: 0;
            top: 0px;
          }
        }
        &.notification-tab{
          right: 110px;
          top: 0px;
        }

        &:not(.is-active) {
          a {
            background-color: transparent;
            text-decoration: underline;
            margin-right: 0;
            color: $color1;
            
            &:hover {
              text-decoration: initial;
            }
          }
        }
      }
    }
  }
  
  .tabs + .tab-content {
    border-top: 4px solid #212121;
    padding: 1rem;
    background-color: white;
    border-radius: 0 4px 4px 4px;
    
    .wizards.box {
      border-radius: 0 0 6px 6px;
    }
  }

  .steps-content {
    padding-bottom: 0 !important;
  }
  .MuiToolbar-root {
    background-color: gray;
  }

  .MuiInputBase-formControl{

    border-radius: 5px !important;
   
   
    
  }
  .MuiInput-root {
    border: 10px solid gray;
    display: flex;
    background-color: white;
   
   
   
    
  }

  .MuiInputBase-input{
    align-self: center;
    border-radius: 5px;
   
    width:30vw;

   
    min-height: 25px;

    &.MuiSelect-select{
      width:3vw !important;
      margin-right: 5px;
      background-color: #FFFFFF;
      border-radius: 5px;
      color: gray;
      font-size: 0.7em;
    }
    
  }
  .MuiInputBase-input>div{
    padding-top: 6px !important;
    border: 1px solid solid #23558C;
    color: gray
    
    
    
  }
  .MTableToolbar-actions-62{
    display: inline-flex;
    flex-direction:row;
    padding-top: 10px;
    position: relative;
  
    width: 130px;
    
  }
  .MTableToolbar-actions-8{
    display: inline-flex;
    flex-direction:row;
    padding-top: 10px;
    position: relative;
  
    width: 7vw;
    
  }
  .MTableToolbar-actions-188{
    display: inline-flex;
    flex-direction:row;
    padding-top: 10px;
    position: relative;
  
    width: 130px;
    
  }
  .has-icon-right{
    z-index: 1;
  }
  .MuiPopover-paper{
    max-width: 5vw !important;
    left: 65vw !important;
  }


 

 

  .step-state {
    display: flex;
    align-items: center;
    font-size: $size-small;
    font-family: "Open Sans Semibold";
    border: 1px solid $primary;
    color: $primary;
    padding: 0 0.5rem;
    border-radius: 12px;
    display: initial;
    margin-right: 8px;
    white-space: nowrap;
    background-color: $white;

    @include touch {
      margin-bottom: 0.75rem;
    }
    
    &.finished {
      background-color: $color13;
      border-color: $color13;
      color: $white;
    }
    
    &.is-important {
      background-color: $yellow;
      border-color: $yellow;
      color: $white;

      @include touch {
        white-space: pre-wrap;
        margin-bottom: 10px;
      }
    }
    
  }

  @import "portal/documents.scss";
  @import "portal/ongoing-applications";
  @import "portal/unfinished-application";
  @import "portal/card-list";
  @import "portal/flow/flow.scss";
}

@import "portal/components.scss";

