.clients-page {

  .is-centered {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-img {
    position: relative;
  }

  .portal-card {
    width: 348px;

    .col-val {
      font-size: 1rem;
      min-height: 1.5rem;
    }

    .portal-card-title .divider {
      display: none;
    }

    .portal-card-data-container .data-column:last-child {
      width: 100%;
      margin-bottom: 0;
      text-transform: lowercase;

      .col-val {
        font-family: $font-family-regular;
      }

    }

    .portal-card-buttons {
      flex-direction: row;

      a {
        width: 145px;
      }

      a:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }


  .search-box {
    max-width: 100% !important;
    position: relative;
    flex: 1;

    input {
      border-left: none;
      border-right: none;
      border-top: none;
      box-shadow: none;
      border-radius: 0;
    }

    .underline {
      font-size: $size-medium;
      padding-right: 45px;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      border-top: 2px solid $active-color;
      height: 0px;
    }

    .search-buttons {
      position: absolute;
      right: 0;
      bottom: 5px;
      display: flex;
      .portal-button:not(:first-child) {
        margin-left: 10px;
      }
    }

  }

  .clients-search-result {
    margin-bottom: 2rem;

    .client-group {
      &-header {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: large;
        font-family: $font-family-extra-bold;

        .divider {
          width: 100%;
          height: 2px;
          background: $primary;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.client-detail-page {
  margin-bottom: 2rem;

  .profile {
    background: linear-gradient($color20,$color3);

    .location-and-date {
      color:$white;
    }

    .portal-button {
      background: transparent;
    }
  }

  &.dashboard {
    .portal-card {
      min-height: 390px ;
    }

  }

}