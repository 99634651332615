@mixin text1 {
  font-size: $size-large;
  font-family: $font-family-bold;
}

@mixin text2 {
  font-size: $size-small;
  font-family: $font-family-extra-bold;
}

@mixin text3 {
  font-size: $size-small;
  font-family: $font-family-bold;
}

.data-columns-container {
  $border-width:1px;
  $column-padding:10px;
  position: relative;
 
  .data-columns {
    position: relative;
    left:-$border-width;
    display: flex;
    flex-wrap: wrap;

    .data-column:not(:first-child) {
      border-left: $border-width solid;
    }
  
    .data-column:first-child {
      padding-left: 0;
    }
  }
}

.guarantee-columns {
  
  .column {

    //overflow-wrap: anywhere;
    word-break: break-all;

    @include desktop {
      margin: 0.5rem 0;

      &:first-child {
        flex: none;
        width: 280px;
      }

      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.16);
        min-height: 55px;
      }
    }

    @include touch {
      &:not(:first-child):not(:last-child), &.has-right-text {
        border-left: 1px solid rgba(0, 0, 0, 0.16);
      }

      min-width: 5.185rem;
    }

  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    
    > div {
      margin: 4px 4px 4px 0;
      @include touch {
        margin-bottom: 8px;
        white-space: pre-wrap;
        font-size: smaller;
      }
    }
  }
  
  .is-narrow {
    @include desktop {
      width: 225px;
    }

    @include touch {
      flex-basis: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
    }
  }
}

.title, .subtitle {
  font-family: "Open Sans Semibold";
  
  &.is-gray {
    color: $label-color;
  }
  &.is-danger {
    color: $danger;
  }
  
  &.is-5 {
    font-size: 1.125rem;
  }
  
}

.chips-filters {

  .chips-filter-container {
    @include desktop {
      padding: 0.5rem 0;
    }

    &:first-child {
      padding-top: 0;
    }
    
    &:last-child {
      @include desktop {
        padding-bottom: 1rem;
      }
    }

    @include touch {
      flex-wrap: wrap;
    }
    
    .button {

      @include touch{
        margin-bottom: 1rem;
      }
      margin-right: 10px;
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
      height: 2em;

      &:not(.is-success) {
        color: #ADADAD;
      }

      .count {
        margin-left: 5px;
      }
    }
  }

}


.button-divider {
  display: inline-block;
  width: 1px;
  @include desktop {
    border-right: 1px solid #D6D6D6;
  }
  height: 30px;
}

.data-column {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin:10px 0;
  justify-content: space-between;

  .col-title {
  
  }

  .col-val {
    font-size: 1.25rem;
    text-transform: uppercase;
    display: flex;
    align-items: baseline;
    .unit {
      padding-left: 0.5rem;
      text-transform: capitalize;
      @include text2();
      @include touch {
        display: block;
      }
    }
  }
}

.small-editable-text {
  .has-text-danger{
    @include touch {
      white-space: pre-wrap;
    }
  }

  .editable-text {
    button {
      padding: 0;
      margin-left: 4px;
      width: 1rem !important;
      height: 1rem !important;
      
      svg {
        width: inherit !important;
        height: inherit !important;
      }
    }
  }
}

.editable-text {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  input {
    font-family: inherit;
    font-size: inherit;
    border: none;
    border-bottom: 1px solid $color2;
    box-shadow: none;
    padding: 0;
    height: 1.7em;
    &:focus {
      border: none;
      outline: none;
      border-bottom: 2px solid $color2;
      box-shadow: none;
      border-radius: 0;
    }
  }

  span {
    word-break: break-all;
  }

  button {
    flex-shrink: 0;
  }

  &-buttons {
    display: flex;
  }
  .underline {
    position: absolute;
    bottom: 0;
    border-top: 2px solid $active-color;
    height: 0px;
  }

  .edit-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.data-box {
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  margin-bottom: 1rem;
  
  &--title {
    font-size: 1.5rem;
    font-family: "Open Sans Semibold";
    color: #23558C;
    cursor: default;
    padding-right: 10px;
    padding-left: 10px;
    height: 50px;
  }
  
  &--body {
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #F5F5F5;
    border-radius: 0 0 4px 4px;
    padding: 10px;
  }
}
.cover-download-button {
  padding: 10px 0 20px 0;
}
