.is-ie{

  box-sizing: border-box;
  overflow: hidden;
  display: block;
  height: 100%;
  position: relative;
  
  .container {
    width: 100%;
  }

  .navbar{
    margin: 0;
  }
  .dashboard {
    &-cards {
      .portal-card {
        height: 1100px;

        .portal-card-buttons {
          position: absolute;
          right: 0px;
        }
      }
    }
  }

  .login-wrapper {
    position: relative;
    top: 50px;
  }

  .limits {
    display: table;
  }

  .modal {
    .modal-card-foot {
      .modal-buttons {
        justify-content: flex-start;
      }
    }
  }

  .step-state {
    display: inline;
  }

  &.close-icon {
    right: -30px;
    display: flex;
  }

  input::-ms-clear {
      display: none;
  }

    .company-name {
      max-width: 126px;
      word-break: break-all;
    }

}