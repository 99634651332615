.is-transparent-header {
  background: transparent !important;
}
.header {
  color: $menu-color;
  font-size: 0.75rem;
  width: 100%;
  height: $portal-header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  z-index: 1000;
  
  @include touch {
    font-size: 100%;
  }

  &.is-transparent-header {
    box-shadow: none;
  }

  .navbar-menu {
    
    @include desktop {
      display: block;
      box-shadow: none;
      flex-shrink: 0;
    }
  }

  .login-button {
    @include touch {
      display: none;
    }
  }

  .navbar {
    width: 100%;
    max-width: $screen-width;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: $menu-color;
    background-color: $header-color;
    z-index: 100;
    
    @include touch {
      .navbar-brand {
        flex-grow: 1;
      }
    }
  }

  .header-button {
    margin-right: 1.75rem;
    
    .dropdown-trigger {
      cursor: pointer;
    }
  
    .dropdown-indicator {
      font-size: 14px;
      margin-left: 2px;
    }
  
    &:not(.contacts-dropdown):hover {
      color: $color1;
    
      svg [id$="-icon-fill"] {
        fill: $color1;
      }
    }
  }
  
  .logout-button {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
}

#langSelector {
  white-space: nowrap;
  padding: 0 4px;
  margin: 0 0.5rem 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  
  @include touch {
    margin-left: auto;
  }
  
  .dropdown-indicator {
    position: relative;
    left: -4px;
  }
}

.dropdown-menu {
  $dropDownMargin: 10px;
  min-width: initial;
  top: 15px;
  
  .navbar-divider {
    margin-left: 30px;
    margin-right: 1rem;
    background: $active-color;
  }
  
  .arrow-up {
    position: relative;
    top: $dropDownMargin;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #D6D6D6;
    
    @include touch {
      top: $dropDownMargin + 10;
    }

    &.is-right {
      left:-35px;
      @include touch{
        left: -50%;
      }
      margin-left: auto;
    }
    .inner-arrow-up {
      position: relative;
      top: 2px;
      left: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }
  
  .dropdown-content {
    margin-top: $dropDownMargin;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    padding: 0;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  
    @include touch {
      margin-top: $dropDownMargin + 10;
    }
    
    .dropdown-item {
      color: #212121;
      font-family: "Open Sans Semibold";
      text-transform: initial;
      line-height: 24px;
      cursor: pointer;
      
      @include desktop {
        font-size: 0.9em;
        padding: 0 20px;
      }
      
      label {
        cursor: inherit;
      }
      
      &.is-selected {
        color: #23558C;
      }
      
      &:hover {
        background: #EFF3F6;
      }
    }
  }
}

.contacts-dropdown {
  .dropdown-trigger {
    color: $danger;
    cursor: pointer;
    font-family: "Open Sans Semibold";
  
    svg.phone-icon {
      background-color: $danger;
      border-radius: 16px;
      padding: 0 2px;
      margin-right: 4px;
    }
  
    svg.dropdown-indicator [id$="-icon-fill"] {
      fill: $danger;
    }
  }
  
  &:hover .dropdown-trigger,
  &.is-active .dropdown-trigger {
    color: $success;
    
    svg.phone-icon {
      background-color: $success;
    }
  
    svg.dropdown-indicator [id$="-icon-fill"] {
      fill: $success;
    }
  }
  
  .dropdown-menu {
    z-index: 1001;
  }
  
  .dropdown-content {
    padding: 0;
  }
}

.contacts-popup {
  cursor: default;
  
  @include desktop {
    width: 380px;
    padding: 5px 10px 10px 10px;
  }
  
  .contacts-header {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 10px;
  }
  
  .popup-title {
    font-family: "Open Sans Bold";
    font-size: 0.875rem;
    padding-top: 5px;
  }
  
  .name-info {
    color: $color1;
    font-family: "Open Sans Semibold";
    font-size: 0.75rem;
    padding: 5px 0;
  }
  
  .phone-info,
  .email-info {
    color: $success;
    font-family: "Open Sans Bold";
    line-height: 2.5rem;
  }
  
  .phone-info {
    font-size: 1rem;
    
    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  
  svg g[id$="-icon-fill"] {
    fill: $success;
  }
}

.company-name {
  max-width: 126px;
  overflow-wrap: break-word;
}

.second-header {
  display: flex;
  align-items: center;
  height: 40px;
  border-top: 1px solid #D6D6D6;
  position: sticky;
  top: 0;
  background: #ffffff;
  box-shadow: 0 5px 10px -5px rgba(18, 64, 105, 0.08);
  z-index: 10;

  .menu-items {
    display: flex;
    align-items: center;
    
  }
  
  &.has-private-menu > .container {
    visibility: hidden;
  }
  
  .header-menu-item {
    font-family: $font-family-bold;
    font-size: 0.75rem;
    color: #4B4B4B;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 14px;
    position: relative;
    top: -1px;
    
    &:hover {
      color: $red;
      
      svg polygon:first-child {
        fill: $red;
      }
    }

    .count {
      font-family: $font-family-regular;
    }

    > a {
      color: inherit;
      font-size: inherit;
    }
    
    svg {
      width: 20px;
      height: 20px;
  
      polygon:first-child {
        fill: #4B4B4B;
      }
    }
  
    &.is-menu .caption {
      display: flex;
      align-items: center;
    }
    
    &.is-active {
      border-radius: 4px 4px 0 0;
      height: 42px;
      
      svg polygon:first-child {
        fill: #ffffff;
      }
      
      &.is-menu {
        color: $red;
        background-color: #ffffff;
        z-index: 9999;
        
        &:before, &:after {
          width:42px;
          height: 42px;
          background-size: 50% 50%;
          background-repeat: no-repeat;
          position: absolute;
          content: "";
        }
        
        &:before {
          background-image: radial-gradient(circle at 0 0, rgba(0,0,0,0) 20px, #fff 21px);
          left: -15px;
          bottom: -26px;
        }
        &:after {
          background-image: radial-gradient(circle at 100% 0, rgba(0,0,0,0) 20px, #fff 21px);
          right: -36px;
          bottom: -26px;
        }
      }
  
      &:not(.is-menu) {
        color: #ffffff;
        background-color: $red;
      }
    }
  }
}

.column-title {
  text-transform: uppercase;
  color: #696969;
  font-size: 0.8rem;
  font-family: "Open Sans Bold";
  
  @include touch {
    font-size: 1rem;
  }
}

.private-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 9999;
  
  .second-header {
    background: transparent;
    box-shadow: none;
    border: none;
    position: relative;

    &.has-private-menu .container {
      visibility: visible;
      
      .header-menu-item:not(.is-menu) {
        pointer-events: none;
  
        &.is-active {
          background-color: #990026;
          color: #e6e6e6;
          opacity: 0.9;
        }
      }
    }
  }
  
  .menu-content {
    padding: 20px;
    background-color: white;
    border-radius: 4px;
  
    a {
      display: block;
      text-decoration: none;
      padding: 5px 0;
      font-size: 0.8rem;
      color: #23558C !important;
    
      &:hover {
        text-decoration: underline;
      }
    }
    
    .columns {
      margin: 0;
  
      .columns > .column {
        padding-left:0;
        padding-right:0;
      }
    }
    
    > .columns > .column {
      padding-top: 0;
      padding-bottom: 0;
      
      &:not(:last-child) {
        border-right: 1px solid #EBEBEB;
      }
    }
  }
}

.modality-curtain {
  background-color: rgba(0, 0, 0, 0.32);
  transition:opacity 0.3s linear;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.navbar.is-mobile {
  .navbar-menu {
    border-top: 1px solid #D6D6D6;
  }
  
  .navbar-item {
    padding: 0.5rem 0.5rem;
  
    &.company-selector {
      flex-shrink: 1;
    }
  
    &.menu-item {
      color: $color1;
      font-family: "Open Sans Semibold";
    
      &.active {
        text-decoration: underline;
      }
    }
    
    &.close-element {
      margin-left: auto;
    }
  
    svg {
      margin-right: 5px;
    }
  }
  
  svg.close-mobile-search {
    align-self: center;
    margin-left: auto;
  
    g[id$="-icon-fill"] {
      fill: $danger !important;
    }
  }
  
  .search-item  {
    flex-grow: 1;
  
    .trade-finance-filter-component {
      display: flex;
      flex-grow: 1;
      
      .simple-filter {
        flex-grow: 1;
        
        > div {
          flex-grow: 1;
          margin: 0 2rem;
        }
      }
    }
  
    svg g[id$="-icon-fill"] {
      fill: $label-color;
    }
  }
  
  .navbar-burger {
    border: 2px solid;
    border-radius: 4px;
    margin-right: 8px;
    height: 2.25rem;
    width: 2.25rem;
    min-width: 1.5rem;
    align-self: center;
    
    &.is-active {
      border-color: transparent;
      margin-left: -0.5rem;
    }
    
    span {
      height: 2px;
    }
    
    &:hover {
      background-color: transparent;
    }
  }
}