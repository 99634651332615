.text-area-with-clause {

}


.modal.enlarged-textarea {

  .field {
    max-width: initial;
  }
  .modal-card {
    @include desktop {
      width: $screen-width - 40px;
    }

    @include touch {
      display: flex;
      width: auto;
    }

    .control,.field-header {
      max-width: initial !important;
    }

    &-head {
      background: $white;
      font-size: 24px;
      color: $primary;
      padding: 8px;
      font-weight: 600;
      box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.08);
    }

    &-foot {
      display: flex;
      justify-content: flex-end;
      box-shadow:0 0 8px 0 rgba(0, 0, 0, 0.32);

      .portal-button {
        width: 190px;
        box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.16);

        &.discard {
          color: $danger;
          border-color: currentColor;
        }

        &.save {
          color: $success;
          border-color: currentColor;
        }
      }
    }

    textarea {
      height: 535px;
      width: 100%;
    }


  }
}


.field {
  label,span {
    .help-icon {
      width:16px;
      height:16px;
      margin-left: 5px;
      cursor: pointer;
    }

  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
    }

    .portal-button {
      background: transparent !important;

      &:hover {
        color: $primary !important;
      }
    }

    label {
      display: flex;
      margin-bottom: 0 !important;
  
      &.is-small {
        font-size: 11px;
      }
    }
  }
}


.field-help {
  position: relative;
  display: flex;

  .help-tooltip {

    @include mobile {
      width: 200px;
    }

    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-bottom: auto;
    -webkit-transform: translate(-50%, -100%);
    background: $black;
    color: $white;
    max-height: 400px;
    max-width: 350px;
    width: 350px;
    padding: 0.5rem;
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    opacity: 0.8;
    z-index: 1000;
    text-align: center;
  }
}

.checkbox-selection-field {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .field {
  }
}


.react-select {
  div[class$="-control"] {
    > div:first-child {
      padding: 0px 4px;
    }
  }
}

.clause-selector-dropdown {
  top:6px;

  @include touch {
    margin-right: -75%;
  }
}
.clause-selector {
  width: 410px;
  height: 380px;
  display: flex;
  flex-direction: column;

  @include touch {
    width: auto;
  }
  .dropdown-item {
    display: flex;
  }

  header {
    font-size: 14px;
    font-weight: 600;
    color:#212121;
    padding:10px;
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }

  section {
    flex:1;
    overflow: auto;
    .dropdown-item {
      padding: 0 !important;
    }

    table  {
      tr {
        height: 42px;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #EFF3F6 !important;
        }

        td {
          font-size: 12px;
          border:none;
          padding: 0.125rem 0.5rem !important;
        }
        td:first-child {
          padding: 0 !important;
          padding-left: 10px !important;
        }

        td:nth-child(2) {
          color: #696969;
          text-align: right;
        }

        td:nth-child(3) {
          color:#212121;
          width: 70%;
        }
      }

    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding:10px;
    .portal-button {
      width: 190px;
      height: 24px;
      min-height:initial;
      font-width: 700;
      font-size: 12px;
      box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.16);

      &:first-child {
        color:$danger;
        border-color: currentColor;
      }

    }
    box-shadow:0 0 8px 0 rgba(0, 0, 0, 0.32);

  }
}



.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  * {
    pointer-events: none;
  }
}

