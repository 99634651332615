.report {
  .top {
    padding-bottom: 2rem;
  }

  &-section:not(:last-child) {
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: $white;
      display: block;
      margin: 10px 0;
    }
  }

  &-section-title {
    font-size: $size-medium;
    position: relative;
    text-transform: uppercase;
    font-family: "Open Sans Semibold";
    margin-bottom: 15px;
  }

  .report-export-setup {
    &-selector {
      padding: 2px;
      width: fit-content;
      margin: 1rem 0;

      .portal-button {
        
        &.active {
          background-color: $primary;
          color: $white !important;
          
        }
  
        &:hover:not(.active) {
          background-color: $white !important;
          color: $primary !important;
        }
      }
    }

  }

  .download-button {
    display: flex;
    justify-content: center;
  }

  .custom-fields {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
}




.report-details-page {

  .label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-weight: normal;
    font-family: "Open Sans Semibold";

    $checkbox-size: 18px;
    input[type="checkbox"] {
      width: $checkbox-size;
      height: $checkbox-size;
      margin-right: 5px;
    }
  }

  .section {
    $section-padding:10px;
    padding:0;

    &--title {
      font-size: 1.5rem;
      font-family: "Open Sans Semibold";
      color: $color1;
      cursor: default;
      padding: 5px $section-padding;

      @include touch {
        padding-left: 0.5rem;
      }
    }

    &--content {
      padding: $section-padding;
      background: $color15;
    }
  }

  .report-type-field {
    display: flex;
    > div {
      width: 20%;
      label {
        margin-left: 6px;
        font-size: 12px;
      }
    }
  }
  .dataIncludeInReport {
    .control {
      max-width: initial !important;

      .field {
        @include desktop {
          width: 20%;
        }
        input {
          flex-shrink: 0;
        }

        label {
          span {
            display: flex;
            flex:1;
          }
        }
      }
    }
  }
}

.reports-page {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .portal-button {
    &.selected {
      background: #35BD5B !important;
      border-color: #35BD5B !important;
      color: $white;
    }
  }
  .actions {
    a, button {
      margin: 0 2px;
    }
  }
  .paging {
    display: flex;
    align-items: center;
  }

  .action-buttons-container {

    .horizontal-buttons{
      display: flex;
      flex-direction: row;
      margin: 0 2px;
      align-items: baseline;

      .card-button {
        margin-right: 1.25rem;
        cursor: pointer;
        font-size: 0.6rem;
        font-family: "Open Sans";
        color: $label-color;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg g[id$="-icon-fill"] {
          fill: $label-color;
        }

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $primary;
          text-decoration: underline;

          svg g[id$="-icon-fill"] {
            fill: $primary;
          }
        }
      }
    }
  }
}