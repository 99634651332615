.columnHideComponent {
    &-title{
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 700;
        color: #23558C;
    }

    &-button {
        cursor: pointer;
        display: flex;
        border: 1px solid #fff;
        padding: 3px;
        border-radius: 2px;
        svg {
            width: 18px;
            height: 18px;
            fill: #fff;
            g {
                g {
                    fill: #fff;
                }
            }
        }
    }

    &-container {
        position: absolute;
        top: -55px;
        right: 35px;
        box-shadow: 0 0.5em 1em -0.125em grey;
        border-radius: 4px;
        padding: 12px;
        background-color: #fff;
        color: #757575;
        text-shadow: none;

        label {
            cursor: pointer;
        }

        input {
            margin-right: 8px;
        }
    }

    &-item {
        &:last-child {
            display: none;
        }
    }

    input[type=checkbox] {
        position: relative;
        cursor: pointer;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            border: 1px solid #000;
            border-radius: 3px;
            background-color: white;
        }
        &:checked:before {
            background-color: #000;
        }
        &:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
        }
    }
}