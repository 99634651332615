#teams {
    position: fixed;
    width: 100px;
    right: 50px;
    bottom: 0;
    cursor: pointer;
    z-index: 999;
    background: #ADADAD;
    line-height: 2;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.75rem;
    font-family: "Open Sans Bold";
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 8px 0;
    border-radius: 4px 4px 0 0;
}

.teams-popup {
    font-family: "Open Sans";
    border-radius: 8px;
    background: #edeef2;
    position: absolute;
    top: -5px;
    width: 300px;
    color:#212121;
    transform: translateX(-50%) translateY(-100%);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    .header {
        background: #fff;
        height: 40px;
    }

    .content {
        padding: 15px;
    }

    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .prompt {
        padding: 5px;
        white-space: pre-line;
        margin-bottom: 5px;
        text-align: left;
        line-height: 1.6;

        .link {
           text-decoration: underline;
        }
    }
    .buttons-container {
        justify-content: flex-end;
        .portal-button {
            background: #47a247 !important;
            border:none;
            color:#FFFFFF;
        }
    }
}