.user-config-page {
  
  > .box {
    padding: 10px;
  }

  .user-config-row {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    margin: 16px 0 0 0;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
  
    .has-grey-background {
      background-color: $color15;
      padding: 13px 10px 10px 10px;
    }
    
    .user-config-row-title {
      padding: 10px;
      line-height: 2rem;
    }
  }

  .user-name {
    color: $primary;
    font-family: "Open Sans Semibold";
    font-size: 1.5rem;
  }

  .user-label {
    color: $label-color;
    @include desktop {
      font-size: 1.5rem;
    }
  }

  .actions-column {
    margin-left: auto;
    font-size: 0.75rem;
    
    .button {
      margin-left: 0.75rem;
    }
    
    svg {
      margin-left: 4px;
    }
  }

  .action-button {
    display: flex;
    align-items: center;
    color: $label-color;
    cursor: pointer;

    svg {
      margin-right: 6px;
    }

    svg g[id$="-icon-fill"] {
      fill: #ADADAD;
    }
  }

  table {

    .limit-notification {
      font-family: "Open Sans Semibold";
      font-size: 0.75rem;
      color: $title-color;
    }

    thead {
      tr {
        th {
          &.number-col {
            width: 150px;
          }
        }
      }
    }
  }

  .portal-buttons button {
    display:flex;
    margin-left: auto;
  }
}

#limit-notification-dropdown {
  padding: 0;
  top: -35px;
  cursor: pointer;

  .dropdown-content {
    padding: 0;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.32);

    &:after {
      border-style: solid;
      border-color: white transparent;
      border-width: 12px 13px 0 13px;
      bottom: -5px;
      left: 10%;
      content: '';
      position: absolute;
    }
    &:before {
      border-style: solid;
      border-color: #999 transparent;
      border-width: 11px 13px 0 13px;
      bottom: -6px;
      left: 10%;
      content:'';
      position: absolute;

    }
  }
}

.number-spinner {
  width: 60px;
  height: 26px;

  input.is-small {
    text-align: right;
    border-radius: 4px 0 0 4px;
    height: inherit;
  }

  .spinner-button {
    height: 13px;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ADADAD;
    color: $white !important;
    cursor: pointer;
    user-select: none;

    &.is-plus {
      border-radius: 0 4px 0 0;
    }
    &.is-minus {
      border-radius: 0 0 4px 0;
    }
  }
}