.popup {
  $triangle-size:10px;
  $bg-color:$white;
  color: $body-color;
  background: $bg-color;
  border: 1px solid #D6D6D6;
  padding: 10px 30px;
  position: absolute;
  font-size: 12px;
  //top: -$triangle-size / 1.4;
  left: 0;
  top:0;
  //transform: translateY(-100%) translateX(-50%);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  z-index: 999;

  .close-icon {
    color: #ADADAD;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    content:"";
    width: 0;
    height: 0;
    left:50%;
    bottom:0;
    border-style: solid;
    border-width: $triangle-size $triangle-size 0 $triangle-size;
    border-color: $white transparent transparent transparent;
    transform: translateY(100%) translateX(-50%);
  }

  &--title {
    font-weight: 600;
    color:#212121;
    text-align: center;
  }

  &--content {
    font-weight: 600;
    text-align: center;
    color:#757575;
  }

}