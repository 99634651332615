.filter {

    color: $menu-color;
    font-size: 0.75rem;
    width: 150px;
    margin: 5px;
    height: 25px;
    border-radius: 5px;
    &.is-apply {

        background-color: #23558C;
        color: white;

    }
   
   


}
.Component-horizontalScrollContainer-16{

    z-index: 0
}
.apply-filter{
    margin-left: auto;
    
}