.segment-selector {
  $box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.4);
  margin-top: -$header-height;
  background-size:     cover;
  background-repeat:   no-repeat;
  //margin-bottom: -$footer-height;
  //min-height: 880px;

  .background {
    width: 100%;
    position: relative;
  }

  .carousel {
    $dotSize:14px;
    position: relative;
    bottom: 0px;
    .carousel-center-controls {
      display: flex;
      margin-bottom: 50px;
      .dot {
        width: $dotSize;
        height: $dotSize;
        border-radius: 50%;
        border: 2px solid white;
        background: $white;
        box-shadow: $box-shadow;

        &.active {
          background: $active-color;
        }

        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }

    .arrow-left,.arrow-right {
      cursor: pointer;
      padding: 0 2rem;
    }

    .arrow-left {
      transform: rotate(180deg);
    }
  }

  .layers {
    position: relative;
    img {
    }
  }
  .layer{
    position: absolute;
    bottom:0;
    display: block;
    width: 100%;
    cursor: initial;
  }

  .segment-details {
    display: flex;
    justify-content: center;
    position: relative;


    .card {
      $button-width:150px;
      color:$color1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      box-shadow: $box-shadow;
      position: relative;
      padding: 2rem 2rem 1rem 2rem;
      top: -2rem;

      &-title {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-bold;
        font-size: $size-large;
        &-separator {
          height: 9px;
          background: $active-color;
          width: $button-width;
          margin: auto;
        }
      }

      &-details {
        padding:1rem 0;
        width: 275px;
        text-align: center;
      }

      &-button {
        width: $button-width;
      }
    }

  }


  /* display: flex;
   justify-content: space-around;
   align-items: center;
   flex-wrap: wrap;
   padding: 16px;
   font-family: $font-family-extra-bold;
   min-height: $min-page-height;

   .segment {
     display: flex;
     flex-direction: column;
     align-items: center;
     color: $active-color;
     font-size: $size-large;
     text-transform: uppercase;

     .segment-name {
       padding-top: 16px;
     }
   }*/
}