.transaction-details {

  background: $white;

  > * {
      margin-bottom: 30px;
  }

  .table {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    &--header {
      display: flex;
      justify-content: space-between;
    }

    &--title {
      color:$color1;
      font-family: "Open Sans Semibold";
      font-size: 24px;
      margin-bottom: 10px;
    }

    &--content {

      &-fees,
      &-drawings {
        table {
          thead {
            tr {
              th {
                font-size: 12px!important;
              }
            }
          }
        }
      }

      &-fees {
        
        tbody.MuiTableBody-root {
          tr {
            td {
              &:nth-child(8) {
                &:hover {
                  color: #23558C!important;
                }
              }
            }
          }
        }
      }

      &-drawings {
        
        tbody.MuiTableBody-root {
          tr {
            td {
              &:nth-child(9) {
                &:hover {
                  color: #23558C!important;
                }
              }
            }
          }
        }
      }    
    }
  }

  .no-wrap {
    white-space: nowrap;
  }


  .state {
    color:$color1;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid;
    border-radius: 12px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

  }

  .transaction-changes {
    table th,
    table td {

      &:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.16);
      }

      &:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.16);
      }

      button {
        &.is-outlined {
          &:not(:disabled) {
            background-color: inherit!important;
          }
          &:hover:not(.is-success):not(.is-danger) {
            background-color: #23558C!important;
            color: white;
          }
        }
      }
    }

    table td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }
  }

  .action {
    display: flex;
    align-items: center;
    color:#757575;
    
    &:hover:not(.is-green) {
      color: $color1;
      text-decoration: underline;
      
      svg {
        fill: currentColor;
      }
    }
    
    svg {
      width: 20px;
      margin-right: 5px;
      * {
        fill:currentColor;
      }
    }

    &.is-green {
      color:#35BD5B;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .state {
    &.waiting_for_signature {
      background: #F5A623;
      color:$white;
    }
    &.refused {
      color:$danger;
    }
  }
}

.event-history-row {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  margin: 16px 0 0 0;
  border: 1px solid #D6D6D6;
  border-radius: 4px;

  .action-column {
    word-break: break-word;
  }
  
  &--title {
    padding: 5px 10px;
    line-height: 1.75rem;
  
    .event-name {
      color: #23558C;
      font-family: "Open Sans Semibold";
      padding-right: 1.5rem;
    }
    
    .event-date {
      color: $label-color;
      font-size: 1.5rem;
    }
  }
  
  .has-grey-background {
    background-color: #F5F5F5;
    padding: 13px 10px 10px 10px;
  }

  .enlarged-field {
    max-width: 50px;

    .action-button {
      display: flex;
      align-items: center;
      color: #212121;
      cursor: pointer;

      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  .actions-column {
    margin-left: auto;
    font-size: 0.75rem;
  
    .action-button {
      display: flex;
      align-items: center;
      color: #757575;
      cursor: pointer;
    }
  }
}

.claims {
  &-table {
    .table {
      &-container {
        table {
          td,
          th {
            border: none;
            &:first-child {
              border-left: 1px solid rgba(0, 0, 0, 0.16);
            }
            &:last-child {
              border-right: 1px solid rgba(0, 0, 0, 0.16);
            }
          }
        }
      }
    }
  }
}