@mixin text1 {
  font-size: $size-large;
  font-family: $font-family-bold;
}

@mixin text2 {
  font-size: $size-small;
  font-family: $font-family-extra-bold;
}

@mixin text3 {
  font-size: $size-small;
  font-family: $font-family-bold;
}

