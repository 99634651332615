.react-select {
  &__control {
    border-color: $input-border-color !important;
    &--is-focused {
      border-color: $primary !important;
      box-shadow: 0 0 0 0.125em $primary!important;
    }

    &--is-disabled {
      border:none !important;
      background-color: $input-disabled-background-color !important;
      .react-select__single-value {
        color:$input-disabled-color !important;
      }
    }
  }
}
