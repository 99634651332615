@import "./variables.scss";
@import "./mixins.scss";
// 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "teams";

a {
  color:#ff0080;
  color:#00ff41;
}

.field-selector {
  $height:30px;
  &--control {
    min-height: initial;
    height: $height;
  }

  &--indicators {
    height: $height;
  }

  &--value-container {
    height: $height;
  }
}

/*

// 2. Set your own initial variables
// Update blue
$blue: #72d0eb;
// Add pink and its invert
$pink: #ffb3b3;
$pink-invert: #fff;
// Add a serif family
$family-serif: "Merriweather", "Georgia", serif;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $pink;
$primary-invert: $pink-invert;
// Use the existing orange as the danger color
$danger: $orange;
// Use the new serif family
$family-primary: $family-serif;

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

// 5. Add new color variables to the color map.
@import "../../node_modules/bulma/sass/utilities/derived-variables.sass";
$addColors: (
        "twitter":($twitter, $twitter-invert),
        "linkedin": ($linkedin, $linkedin-invert),
        "github": ($github, $github-invert)
);
$colors: map-merge($colors, $addColors);
*/
// 6. Import the rest of Bulma

@font-face {
  font-family: 'Open Sans';
  src: url('../webfonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Semibold';
  src: url('../webfonts/OpenSans-SemiBold.ttf') format('truetype');

}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('../webfonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Extrabold';
  src: url('../webfonts/OpenSans-ExtraBold.ttf') format('truetype');
}

/**to avoid flickering*/
body:after {
  display: none;
}

.bg-image {
  background-position: center;
  height: 720px;
}

.ml-auto{
  margin-left: auto;
}

@import "~bulma/bulma";
//@import "~bulma-checkradio/dist/css/bulma-checkradio";
@import "components/all.scss";
@import "components/wizard.scss";
@import "pages/all.scss";

html, body {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
}

.root {
  flex: 1;
  background:$white;
}

body,
button,
input,
select,
textarea {
  font-family: 'Open Sans', sans-serif;
}

.page {
  min-height: calc(100vh - #{$header-height + 10px});
}

#page-wrap {

}

.container {
  max-width: $screen-width;
  
  &.has-background {
    background-color: #ffffff;
  }
}


.app-content {
  min-height: calc(100vh - #{$portal-header-height});
  background-color: #F0F0F0;
  
  @include touch {
    padding: 0 10px;
  }
}

.second-footer {
  background-color: $footer-color;
  height: $footer-height / 2;
  border-top: 1px solid #EBEBEB;
  width:100%;
  font-size: 12px;
  display: flex;

  @include touch {
    height: 90px;
  }

  .footer-contacts {
    @include touch {
      margin-top: 0.75rem;
    }
    margin: auto;
  }
}

.footer {
  display: flex;
  align-items: center;
  z-index: 998;
  margin: auto;
  color: rgba(0, 0, 0, 0.87);
  background-color: $footer-color;
  height: $footer-height / 2;
  width:100%;
  padding:0;
  font-size: 0.75rem;
  box-shadow: 0 -5px 10px -5px rgba(18, 64, 105, 0.08);

  &-logo-and-text {
    display: flex;
    padding-top:0;
    @include touch {
      margin: auto;
    }
    
    .footer-text {
      text-transform: uppercase;
    }
  }

  &-logo {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    padding:0 2rem;
  }

  &-icons {
      display: flex;
      justify-content: center;
      margin-bottom:1rem;
      a:not(:last-child) {
        margin-right:2rem;
      }
  }

  &-text {
    margin: 0 1rem;
    padding:0;

    &-long {
      display:none;
      @include desktop {
        display: inline;
      }
    }
  }

  .footer-contacts {
      margin-left: auto;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  max-width: $screen-width;
  padding: 15px;
  height: $footer-height;

  @include touch {
    flex-direction: column;
  }

  .icons {
    display: flex;
    flex: 0.5;
    justify-content: space-between;
    align-items: center;
    @include touch {
      padding-top: 5px;
      flex: 1
    }
  }

}

.root {

}

.DayPickerInput {
  width: 100%;
}

.DayPicker-Day--today {
  color: $color1 !important;
}

.DayPickerInput-Overlay {
  z-index: 1000 !important;
}

.day-picker-icon {
  color: #ADADAD !important;
  z-index: initial !important;
  cursor: pointer;
  svg {
    cursor: pointer;
    width: 20px !important;
    height: 22px !important;
  }

}

.select:not(.is-multiple)::after {
  border: 1px solid $color1;
  border-right: 0;
  border-top: 0;
}

.button.is-outlined:not(:disabled) {
  background-color: $white;
  @include touch {
    //margin: 0.25rem;
  }
}


.control {
  max-width: $field-max-width !important;
  .input{
    &.rounded-input {
      border-radius: 30px;
    }
    &.is-medium {
      &.rounded-input {
        height: 36px;
        font-size: 1rem;
      }
      &.rounded-input ~ span.icon.is-small {
        font-size: 1rem;
      }
    }
  }
}

$icon-size: 2.5em;

.portal-icon {
  color: $icon-color;
  width: $icon-size;
  height: $icon-size;
  line-height: 0;

  i {
    line-height: 0;
  }
}

i[class^="portal-icon-"] {
  font-size: $icon-size;
}

.small-icon {
  transform: scale(0.85);
}

.wizard-messages {
  padding: 1.5rem;

  .icon {
    font-size: 1.5rem;
    color: $primary;
  }

  &-title {
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;

    p {
      padding-left: 1.5rem;
    }
  }
}

.wizard-last-page {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    width: 100%;
  }
}

.asterisk {
  color: $red;
}

.img-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.confirm-dialog {
  width: 300px;

  .box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .portal-buttons {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
  }
}

.horizontal-padding {
  @include touch {
    padding: 0 0.5rem;
  }
}

.has-right-text {
  @include desktop {
    text-align: right !important;
  }
  
}

.has-text-success {
  border-color: $success !important;
}

.is-fullwidth {
  width: 100%;
  
  #sort-option-field > div {
    min-width: 200px;
  }
}

.is-flex {
  display: flex;
  align-self: center;
  align-items: center;
  
  &.is-right {
    margin-left: auto;
  }
}

.is-bold {
  font-family: $font-family-bold;
}

.is-transparent {
  background: transparent !important;
}

.is-mobile-only {
  @include tablet {
    display: none !important;
  }
}

.is-desktop-only {
  @include touch {
    display: none !important;
  }
}

//COLLAPSIBLE CONTAINER
.collapsible-container-header-closed{
  border-bottom: 1px solid #d8d8d8!important;
}

.collapsible-container-header{
  display: flex;
  margin-bottom: 0!important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.collapsible-container-header-title{
  flex: 1;
}

.collapsible-container-header-icon{
  display: inline-block;
}

.opened-collapsible-container-colored{
  background-color: #EEFAFF;
}

.opened-collapsible-container-border{
  border-top: 1px solid #d8d8d8!important;
}
//COLLAPSIBLE CONTAINER END

//Product name width
.trade-finance .products-cart .row.price .field:first-child {
  width: 1000px;
}
.trade-finance .products-cart .row.price .field:first-child .control {
  max-width: 562px!important;
}

//ConsumerFinance
.loan-calculator .portal-card.loan-summary.has-top-margin{
  margin-top: 50px!important;
  margin-bottom: 10px!important;
}
.portal-card-header .portal-card-title .divider{
  height: 8px;
  background: linear-gradient(90deg,#00d0f2,#00a3e0);
  border-radius: 5px;
  margin: 0 auto;
  width: 120px;
}

//forgotten password
.reset-password{
  color: #1b3c59!important;
  display: block!important;
}

.reset-password .login-form{
  width: inherit!important;
}

.login-page .form-container.reset-password h1{
  font-family: "Open Sans Extrabold";
  text-transform: uppercase;
  font-size: 200%;
}

.login-page .form-container.reset-password h2{
  font-size: 150%;
  margin-bottom: 0.5rem;
}

.reset-password hr{
  margin: 0.7rem 0;
}

.login-page .form-container.reset-password h3{
  margin: 0.5rem 0;
  font-weight: bold;
}

.login-page .login-form .devider{
  height: 8px;
  background: linear-gradient(90deg,#00d0f2,#00a3e0);
  border-radius: 5px;
  width: 20%;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.reset-password button{
  margin-right: 30px;
}

.login-page .form-container.reset-password{
  max-width: 1000px;
}

.reset-password .email{
  margin-top: 0.3rem;
  max-width: 400px!important;
}

.reset-password hr{
  height: 2px;
}

.documents-page-main-entity-icon {
  height: 40px;
  width: 40px;
}

.flex-1-1{
  flex: 1 1;
}

.buttons-container {
  display: flex;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
  &.list-button-container {
    padding: 0 1rem 0 1rem
  }
}

.app-notification {
  font-size: 0.75rem;
  font-family: "Open Sans Semibold";
  position:relative;
  top: 0;
  text-align: center;
  
  .is-danger {
    color: $danger;
    background-color: transparent;
  }
}

.app-indicator {
  background: transparent !important;
}

.help {
  font-family: "Open Sans Semibold";
  white-space: pre;
}

.top-margin {
  margin-top: 1rem;
}

.bottom-margin {
  margin-bottom: 1rem;
}

.button {
  a {
    color:$button-text-color;
  }
}

.is-yellow {
  background: $yellow !important;
  color:$white !important;
  border-color: $yellow !important;
}

.is-underlined {
  text-decoration: underline;
}

.search-icon {
  svg {
    * {
      fill:$white
    }
  }
}

hr {
  margin: 0.5rem 0;
}