.wizard {

  .layout-container {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  .container-title {
    font-family: "Open Sans Semibold";
    margin-bottom: 0.75rem;
  }

  .messages {
    .container-title {
      padding-bottom: 10px;
    }
  }

  .select {
    &::after {
      border-width: 0px 0px 2px 2px;
      border-color: $primary;
    }
  }

  input, select, .react-select, textarea {
    opacity: initial;
  }
  
  .label {
    color: #757575;
    font-weight: normal;
    font-family: "Open Sans Semibold";
    
    &.checkbox {
      justify-content: flex-start;
      
      input {
        margin-right: 4px;
      }
      
    }
  }

  .label-width {
    @include mobile {
      max-width: 225px;
    }
  }

  .notification {
    font-family: $font-family-bold;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
  }

  .wizard-header {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: $active-color;
    font-size: $size-large;
    font-family: $font-family-bold;
  }

  .buttons {
    padding-top: 1rem;
    & > button:not(:first-child) {
      margin-left: 10px;
    }
  }

}