.portal-card {
  $imageSize: 70px;
  background-color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color1;
  border-radius: 6px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  &-header {
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 10px 0 0 10px;
  
    .portal-card-subtitle {
      color: rgba(0, 0, 0, 0.54);
      font-size: 11px;
    }
    
    svg {
      margin: 10px 10px 5px 0;
    }
  }

  &-image {
    width: $imageSize;
    height: $imageSize;
    margin: 0 auto;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
  }

  &-data-container {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    border-bottom: 1px solid $color1;

    .data-column {
      width: 50%;
      align-items: center;

      .col-title {
        color: $greyText;
        font-size: 12px;
        text-align: center;
      }

      .col-val {
        font-size: 1.6rem;
      }

      &:nth-child(even) {
        border-left: 1px solid $color1;
      }
    }

  }
  
  .portal-card-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #F5F5F5;
    position: absolute;
    bottom: 0;

    button {
      margin: 0.75rem;
      font-size: 0.75rem;
      width: 100%;
      height: 55px;
      color: #757575;
      font-family: "Open Sans Bold";
      
      &:hover {
        background-color: #757575;
        border-color: #757575;
        color: #ffffff;
      }
    }
  }


  .font-size {
    &-small {
      .col-val {
        font-size: 1rem;

        .unit {
          font-size: 0.6rem;
        }
      }

    }

    &-medium {
      .col-val {
        font-size: 1.5rem;

        .unit {
          font-size: 1.1rem;
        }
      }
    }

    &-large {
      .col-val {
        font-size: 1.7rem;

        .unit {
          font-size: 1.3rem;
        }
      }
    }
  }

}