.request-item-details {
  padding:0.5rem 0 !important;
  margin-bottom: 1rem;

  .calculation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .calculation-details-container {
    margin-left:0;
    .calculation-details {
      padding:0 !important;
    }
  }

  .has-text-overflow {
    .value {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  background: $color15 !important;
  .address {
    border-left: none !important;
    .value {
      width: 200px;
    }
  }

  .description {
    .value {
      max-width: 500px;
    }
  }

  .url {
    .value {
      max-width: 350px;
      a {
        color:$primary;
      }
    }
  }

  img,a {
    align-self: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
  }

}


.application-result-OK {
  margin-top: 1rem;
  font-weight: bold;
  font-size: $size-medium;
  color:green;
}

.application-result-KO {
  margin-top: 1rem;
  font-weight: bold;
  font-size: $size-medium;
  color:red;
}


.ko-criteria {
  &-content {
    padding-left: 42px;
  }
  &-title {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    div:first-child {
      font-family: $font-family-bold;
    }
    .portal-button {
      margin-left: 1rem;
    }
  }
}
.ko-criteria-table {
  table {
    thead {
      th {
        text-transform: uppercase;
        height: 36px;
        text-align: center;
        border:none;
        font-family: $font-family-bold;
      }
    }

    tbody {
      tr {
        td {
          border:none;
          height: 36px;
          text-align: center;
          &:first-child {
            text-align: left;
          }
          .result {
            height: 28px;
            width: 80px;
            text-transform: uppercase;
            text-align: center;
            margin: auto;
            border-radius: 6px;
            font-family: "Open Sans Bold";
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;

            &.is-OK {
              background: #28D1AD;
            }
            &.is-KO {
              background: #F97A94;
            }
          }
        }
      }
    }
  }
}