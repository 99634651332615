.dashboard {

  .calendar {
    margin:20px 0;
  }

  .is-green {
    color: forestgreen;
  }

  &-cards {
    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-number {
      font-size: 50px;
      font-family: $font-family-bold;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &-number-normal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: $font-family-bold;
    }


    .portal-card {
      min-height: 340px;
      padding-bottom: 80px;
      position: relative;

      &-title {
        font-size: 1rem;
        font-family: "Open Sans Semibold";
        color: #23558C;
      }

      .vertical-margin {
        margin: 1rem 0;
      }
    }

    .empty-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    .chart {
      position: relative;
      padding: 2rem 0;

      .chart-center {

        &-title {
          font-size: 0.75rem;
          position: relative;
          color: $greyText;
        }

        &-value {
          font-size: 2.5rem;
          font-family: $font-family-extra-bold;
        }

        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
      }
    }

    .card-title {
      padding-top: 0.5rem;
      font-size: 0.75rem;
    }

    .documents {
      margin-top: 3rem;
    }

  }


  .notification-list,.signature-list {
    width: 100%;
    margin-top:1rem;
    &--item {
      background: linear-gradient(180deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 50%);
      padding: 5px 10px;
      cursor: pointer;
  
      &:hover {
        box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.25);
      }

      &-row {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        color: #757575;
  
        .amount {
          font-size: 1rem;
          line-height: 0.75rem;
        }
        
        .amount, .beneficiary {
          font-family: "Open Sans Semibold";
          color: #212121;
        }
        
        .currency {
          font-family: "Open Sans Semibold";
        }
  
        .group-a {
          color: $red;
        }
        
        .subject {
          color: #23558C;
          font-family: "Open Sans Semibold";
        }
      }
  
      .divider {
        display: block;
        height: 2px;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        margin-top: 2px;
      }
    }
  }

  &-filter {
    margin-bottom: 1rem;

    .trade-finance-filter-component .simple-filter .search-box {
      @include desktop {
        min-width: 600px;
      }
    }
  }

}

.profile {
  margin-left:30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  &-avatar {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  
  &-name {
    font-size: $size-large;
    font-family: $font-family-bold;
  }
  
  &-data {
    display: flex;
    align-items: center;
    
    select {
      border: 0;
      padding-right: 0.5em;
    }
    
    .select::after {
      border-color: #adadad !important;
    }
    
  }
  
  &-data-columns {
    display: flex;
    flex-wrap: wrap;
    
    .data-column:not(:first-child) {
      border-left: 1px solid $color1;
    }
    
    .data-column:first-child {
      padding-left: 0;
    }
  }
  
}