$document-icon-color: $active-color;
$document-color: $primary;


.comment-document--file-type {
  margin-bottom: 1rem;
}

.uploaded-documents {

  .multi-line {
    > *:not(:first-child) {
      margin-top: 5px;
    }
  }

  tr {

    background: linear-gradient(180deg, rgba(0, 0, 0, .04), transparent 50%);

    &.document-highlighter{
      background-color: $highlighter-color;
    }

    &:hover {
      box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.25);
    }

    td {
      padding: 15px;
    }

    .column--description {
      width: 50%;
    }
  }

  .filename-and-user {
    display: flex;
    align-items: flex-start;

    svg {
      margin-right: 10px;
    }

    .create-user-name {
      //color: $input-color;
      //font-family: "Open Sans Semibold";
      margin: 0px;
    }
    .create-user-details {
      color: $color1;
      margin: 0px;
    }
  }

  .actions-column {
    .buttons {
      padding: 0;

      > *:not(:first-child) {
        margin-left: 5px;
      }

      .card-button {
        color: #757575;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        align-items: center;

        svg g[id$="-icon-fill"] {
          fill: #757575;
        }
      }
    }

  }
}