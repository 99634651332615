.table {
    font-size: .75rem;
    &-filterSection {
        border-radius: 4px;
        font-size: .75rem;
        position: relative;
        font-family: "Open Sans";
    }

    &-container {
        width: 100%;
        overflow-x: auto;
    }

    &-top {
        background-color: #757575;
        text-shadow: 1px 1px rgb(0, 0, 0 / 25%);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 10px;
    }

    &-bottom {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display: flex;
        justify-content: space-between;
        padding: 16px;

        @media screen and (max-width: 789px) {
            flex-direction: column;
        }

        &--filters {
            & > div {
                margin-bottom: 16px;
                width: 100%;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @media screen and (max-width: 789px) {
                justify-content: center;
            }
        }
    }

    &-th {
        &-amount,
        &-drawingAmount {
            text-align: right!important;
        }
        &-drawingAmount {
            width: 130px;
        }
        &-action {
            .SortArrowComponent {
                display: none;
            }
        }
    }
    &-td {
        &-amount,
        &-drawingAmount {
            text-align: right!important;
        }
    }

    &-table {
        width: 100%;
        thead {
            tr {
                th {
                    height: 2rem;
                    vertical-align: middle;
                    font-weight: normal;
                    text-shadow: 1px 1px rgb(0, 0, 0 / 25%);
                    font-family: "Open Sans";
                    &:first-child {
                        width: .4rem;
                    }
                    &:last-child {
                        text-align: center;
                        & > div {
                            & > div {
                                display: flex;
                            }
                        }
                        span {
                            display: none;
                        }
                    }

                    input[type=checkbox] {
                        &:before {
                            border: 1px solid #fff;
                            background-color: #757575;
                        }
                        &:checked:before {
                            background-color: #757575;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.16);
                &:nth-child(even) {
                    background-color: rgb(245, 245, 245);
                }
                td {
                    height: 2rem;
                    vertical-align: middle;
                    border-right: 2px solid rgba(0, 0, 0, 0.16);
                    &:last-child {
                        text-align: center;
                        border-right: 1px solid rgba(0, 0, 0, 0.16);
                        & > div {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    &-headerContent {
        display: flex;
        align-items: center;
    }

    input[type=checkbox] {
        position: relative;
        cursor: pointer;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            border: 1px solid #000;
            border-radius: 3px;
            background-color: white;
        }
        &:checked:before {
            background-color: #000;
        }
        &:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
        }
    }
}

.react-table {
    width: 100%;
}