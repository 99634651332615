.DateBetweenFilter {
    display: flex;
    font-size: 12px;
    justify-content: space-between;

    @media screen and (max-width: 789px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
    }

    &-datepicker {
        &--span {
            display: flex;
        }
    }

    label {
        display: flex;
        align-items: center;

        &:last-child {
            margin-left: 8px;
        }

        span {
            margin-right: 6px;
        }

        @media screen and (max-width: 789px) {
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            width: 100%;

            &:last-child {
                margin-left: 0;
            }
        }
    }
    svg {
        g{
            g{
                fill: #23558C;
            }
        }
    }

    .datepicker {
        input {
            width: 100px;
        }
    }
}