.calendar-compact {

  &--days-container {
    display: flex;
    position: relative;

  }

  .show-whole-calendar {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #ADADAD;
    cursor: pointer;
  }

  &--days {
    display: flex;
    @include touch {
      overflow-x: auto;
    }
  }

  &--control {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EBEBEB;
    width: 30px;
    cursor: pointer;
    user-select: none;
    border: none;
  }

  &--day {
    position: relative;
    .calendar-events {
      top: 80px;
    }

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 64px;
      background: $white;
      border-bottom: 3px solid transparent;

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        z-index: 1000;
        cursor: pointer;
      }

      &.has-event {
        border-bottom: 3px solid $red !important;
      }

      &.is-today {
        border: 2px solid #23558C;
      }

      &.is-holiday {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%);
      }

      .day-no {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600;
      }

      .day-name, .month {
        font-size: 10px;
        color: #ADADAD;
        width: 38px;
        display: flex;
        justify-content: center;
      }

      .day-name {
        border-bottom: 1px solid currentColor;
        padding-bottom: 2px;
      }
    }
  }
}

.calendar-events {
  
  @include desktop {
    position: absolute;
    z-index: 1000;
  
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: $white;
      position: absolute;
      left: 10px;
      top: 0px;
      border-top: 1px solid #D6D6D6;
      border-left: 1px solid #D6D6D6;
      transform: rotate(45deg) translateY(-70%);
      z-index: 999;
    }
  }
  
  background: $white;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  white-space: nowrap; //popup is wider than parent
  
  &--container {
    padding: 0 15px;
    max-height: 345px;
    overflow-y: auto;
  }

  &.is-right {
    right: 0;

    &::before {
      left: auto;
      right: 28px;
    }
  }


  &--event {
    padding: 10px 0;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        margin-bottom: 5px;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #D6D6D6;
    }

    .event-title {
      font-size: 14px;
      font-weight: 600;
      color: $red;
    }

    .event-identifier {
      font-size: 12px;
    }

    .event-otherPartnerName {
      font-size: 12px;
      color: #23558C;
      font-weight: 600;
    }

    .event-amount {
      margin-left: 50px;
      color: #212121;
      font-size: 16px;
      font-weight: 600;

      .currency {
        margin-left: 5px;
        font-size: 12px;
        color: #757575;
      }
    }

    .portal-button {
      color: #757575 !important;
      font-size: 11px;

      &:hover {
        background: #757575 !important;
        color: $white !important;

        svg {
          * {
            fill: $white !important;
          }
        }

      }

      svg {
        * {
          fill: #757575 !important;
        }
      }
    }

  }

}

.calendar-whole {
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .current-year {
      font-size: 44px;
      font-weight: 600;
      color: #2F333E;
    }

    .year-selector {
      display: flex;

      > button {
        font-size: 18px;
        color: #ADADAD;
        font-weight: 600;
        width: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;

        &:focus {
          border: none;
          outline: none;
        }

        &:hover {
          color: #23558C;

          svg {
            * {
              fill: #23558C;
            }
          }
        }

        &.today {
          border-left: 1px solid #D6D6D6;
          border-right: 1px solid #D6D6D6;
        }
      }
    }
  }

  .months-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include touch {
      justify-content: space-around;
    }
  }

  .calendar-month-view {
    $day-size: 44px;

    width: 7 * $day-size;
    margin-bottom: 15px;

    &--header {
      font-size: 24px;
      color: #23558C;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .day-names {
      display: flex;
      font-size: 18px;
      color: #757575;

      > div {
        text-align: center;
        width: $day-size;
      }
    }

    &--days {
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        color: rgba(0, 0, 0, 0.87);
        font-size: 22px;
        font-weight: 600;
        width: $day-size;
        height: $day-size;
        position: relative;
        user-select: none;

        &.has-event {
          cursor: pointer;
          &:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            z-index: 100;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom:0;
            height: 3px;
            width: 24px;
            background: $red;
          }
        }

        &.is-today {
          border: 2px solid #23558C;
        }

        &.is-holiday {
          background: rgba(35, 85, 140, 0.08);
        }

        .calendar-events {
          top:60px;
          &.is-left {
            left:-8px
          }
          &.is-right {
            right:-8px;
          }
        }
      }
    }

  }

}