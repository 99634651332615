$step-padding: 42px;

@mixin step-padding {
  padding-left: $step-padding;
  @include touch {
    padding-left: 1rem;
  }
}

.hidden {
  display: none;
}

.steps-segment {
  border: 1px solid #D6D6D6;
  border-radius: 4px 4px 0 0;
  margin-bottom: 15px;
  
  &.claim-event-sign-step {
    margin-top: -20px;
    border-top: 0;
    
    .step-header {
      display: none !important;
    }
  }
}

.floating-save {
  position: sticky;

  &.is-ie{
    position: fixed;
    @media screen and (min-width: 1024px) {
      right: 140px;
    }
    @media screen and (min-width: 1360px) {
      right: 263px;
    }
    @media screen and (min-width: 1680px) {
      right: 438px;
    }
    @media screen and (min-width: 1920px) {
      right: 570px;
    }
  }

  @include desktop {
    text-align: center;
    display: flex;
    top: 335px;
    margin-right: -140px;
    align-items: end;
    justify-content: flex-end;
    margin-bottom: -100px;
  }
  @include touch {
    top: 0;
    z-index: 1;
  }
     .arrow-left {
       &.is-ie {
         position: fixed;
         top: 365px;
         @media screen and (min-width: 1024px) {
           right: 132px;
         }
         @media screen and (min-width: 1360px) {
           right: 255px;
         }
         @media screen and (min-width: 1680px) {
           right: 430px;
         }
         @media screen and (min-width: 1920px) {
           right: 562px;
         }
       }
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right:10px solid white;
      align-self: center;
    }
  .card, .container{
    @include desktop {
      width: 133px;
      height: 80px;
    }

    .content {
      display: grid;
      color: #5C5C5C;
      font-family: "Open Sans Semibold";
      font-size: 12px;

      .floating-elements {
        @include desktop {
          padding: 10px 10px 20px 10px;
        }

        @include touch {
          display: flex;
          background: white;
          margin-bottom: 10px;
          align-items: center;
          height: 2.5rem;
        }

        button {
          width: 100px;
          height: 24px;
          min-height: auto;
          margin-bottom: 0;

          &.loading {
            background-color: $color1;
          }
        }
        .floating-text {
          @include desktop {
            margin-bottom: 10px;
          }
          @include touch {
            padding: 1rem;
          }
          &.green{
            color: rgb(53, 189, 91);
          }
        }
      }
    }
  }

}

.flow-page {

  .steps-segment:not(:last-child):after {
    left: 30px !important;
    width: 0.13em !important;
    background-color: $color1 !important;
    top: 4.5rem !important;
    bottom: 0.5rem !important;
  }

  .step-state {
    flex-shrink: 0;
    align-self: center;

    @include touch {
      white-space: pre-wrap;
    }
  }

  .calculation-wrapper {
    background-color: $white;
    width: 100%;
    padding-bottom: 25px;
    @include touch {
      padding-bottom: 12px;
    }

    .small-text {
      font-size: 0.75rem;
      margin-left: 2px;
    }

    padding-left: $container-padding;
    padding-right: $container-padding;

  }

  .wizards-wrapper {
    background-color: $white;
    width: 100%;

    .step-icon {
      width: 100%;
      height: 100%;
    }

    .fill-the-form {
      font-family: $font-family-bold;
      font-size: $size-medium;
      text-transform: uppercase;
      color: $white;
      background-color: $color1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $header-height;
      padding: 0 1rem;
      text-align: center;
    }

    .wizards {
      
      @include touch {
        .container {
          margin: initial;
        }
      }

      .step-header {
        display: flex;
        align-items: center;
        width: initial;
        @include until($widescreen) {
          margin: 0 0.5rem;
        }

      }

      .edit-wrapper.active {
        background-color: $color15;
      }

      .step-container {
        min-height: 2rem;
        padding: 10px;
        width: 100%;
        @include touch {
          //padding-left: 29px;
        }

        @include desktop-only {
          padding-left: 38px;
        }

        .control {
          max-width: initial !important;
        }

      }
  
      .step-body {
        background-color: #F5F5F5;
  
        .step-container-body {
          &:not(.is-block) {
            display: flex;
            flex: 1;

            @include touch {
              justify-content: center;
            }
          }
          
          &.sign .portal-buttons {
            margin-bottom: 10px;
          }
        }

        .button-container {
          flex: 1 1;

          .download-button-divider {
            display: flex;
            margin-bottom: 1rem;
            @include desktop {
              padding-bottom: 1rem;
            }
            border-bottom: 1px solid #d6d6d6;

            .button-info {
              color: $input-color;
              font-weight: $weight-semibold;
              font-size: 12px;
              align-self: center;
              margin-left: 16px
            }
          }
        }
      }

      .step-title {
        font-size: 1.5rem;
        color: $color1;
        cursor: default;
        padding-right: 10px;
        padding-left: 10px;
        font-weight: 600;

        @include touch {
          padding-left: 0.5rem;
        }
      }

      .steps-marker {
        flex-shrink: 0;
        height: 50px;
        @include touch {
          height: 45px;
        }
        background-color: transparent !important;
      }

      .wizard-content {
        @include touch {
          padding-left: 0 !important;
          .horizontal-padding {
            padding-left: 0;
          }
        }

        .button-container {
          .button {
            box-shadow: none;
          }

          &:nth-child(2) {
            .button {
              background-color: $white;
              border: none;
              color: $color1;
            }
          }
        }
      }
    }

  }
  
  .sign-step {
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    padding: 10px;
    
    .group-members {
      font-size: 0.75rem;

      .name {
        display: flex;
        align-items: center;
        svg {
          width:11px;
          height: 11px;
        }
      }
    }
  }
  
  .sign-hint {
    font-size: 0.75rem;
    color: #757575;
  }
  
  .step-hint {
    font-size: 0.75rem;
    color: $label-color;
    
    &.has-top-border {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #D6D6D6;
    }
  
    &:not(.has-top-border) {
      padding-bottom: 8px;
      border-bottom: 1px solid #D6D6D6;
    }


      &.on-modal {
        @include touch {
          display: flex;
        }
        margin: 0px 5px 0px 5px;
        border-bottom: none;
      }

    svg { margin-right: 8px;
      path {
        fill: #ADADAD;
      }
    }
  }

  .steps-marker {
    background-color: $color1 !important;
  }

  .editable-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    &-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0.2rem 0;
    }
  }

  .editable-list-item {
    flex: 1;
  }

  .editable-list-item-buttons {
    overflow-x: hidden;
    @include desktop {
      //min-width: 80px;
    }
    display: flex;
    align-items: center;

    .item-status-finished {
      font-size: 2.4rem;
      color: $color1;
    }

    img {
      display: flex;
    }

    .portal-button {
      height: 36px;
    }

  }

  .item-columns-container {
    overflow: hidden;
    line-height: 1.5;
    display: flex;
    flex: 1;

    .item-columns {
      min-width: 0; //text overflow hack
    }
  }

  .item {
    $divider-size: 1px;

    &-columns {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$divider-size;
      align-items: center;
      flex: 1
    }

    &-column {
      border-left: $divider-size solid $label-color;
      padding: 0 1rem;

      &:first-child {
        padding-left: 0;
        border-left: 0;
      }
    }
  }


  .details-comp {
    @include touch {
      padding-left: 0rem;
    }

    .wizard {
      
      @include touch {
        padding-left: 1rem;
      }
    }

    .details-header {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      &-title {
        display: flex;
        flex: 1;
        font-family: $font-family-bold;
        font-size: $size-medium;
        color: $color1;
      }

      &-buttons {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-left: auto;
      }

      .editable-list-item {
        max-width: initial;
        width: initial;
      }

      .step-container {
        padding-left: 0px;

        &::after {
          content: none;
        }
      }

      .step-container-body {
        padding-left: 0;
      }
    }

    .details-footer {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      &-buttons {
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }

}

.documents {
  flex: 1;

  @include touch {
    width: 100%;
  }

  .document-edit {
    flex: 1;
  }

  .documents-content {
    display: flex;
    flex-direction: column;

    .step-title {
      padding-left: 15px !important;
    }
  
    .loading-indicator {
        margin: auto;
        width: 650px;
        height: 140px;
        display: flex;
        border-style: dashed;

      @include touch {
        width: 314px;
        height: 126px;
      }
    }

    .documents-list {
      min-height: 1rem;

      .document-list-item {
        .item-column {
          //word-break: break-all;
          @include desktop {
            flex: 1;
          }
        }
      }

      .webdav-icon {
        width: 20px;
        height: 20px;
        g {
          fill: #ADADAD;
        }
      }
    }

    .document-edit-content {
      padding-bottom: 1rem;
    }

    .document-upload .control {
      max-width: 100% !important;

      .file-name {
        max-width: initial;
      }
    }

    .documents-buttons {
      padding-top: 0.5rem;
    }
  }

  .document-list-item {
    .file {
      .file-input {
        width: 30px;
        height: 30px;
      }
    }
  }

}

.finish-application-step {
  padding-left: 90px;
  padding-bottom: 1rem;
}

img.status-img {
  width: 36px;
  height: 36px;
}


.finish-date {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $size-medium;
  font-family: "Open Sans Bold";
  color: white;
  background: $color1;
  text-transform: uppercase;
  padding: 1rem 0;
  margin: 1rem 0;
}

.document-requirements-step {
  width: 100%;

  .empty-step-body {
    display: flex;
    flex: 1 1;
  }

  textarea {
    height: 250px;
  }

  .box .columns .column:first-child {
    max-width: 30%;
  }

  .box .portal-button {
    margin-left: 4px;
    margin-right: 4px;
  }

}

.installments-step {
  flex: 1
}

.name.is-special {
  color: $color1;
}

