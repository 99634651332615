.login-page {
  //padding: 2rem $container-padding;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - #{$header-height + $footer-height - 10px}) !important;
  padding-top:50px;
  position: relative;

  @include touch {
    min-height: 100vh !important;
  }

  .is-danger {
    font-family: $font-family-bold;
  }

  .login-wrapper {
    top: 20%;
    position: absolute;
    @include touch {
      width: 100%;
      padding: 0 1rem;
    }
  }

  .form-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    height: 100%;

    .login-form {
      width: 100%;
      @include desktop {
        width: 450px;
      }

      input {
        //   background-color: #9eefe6;
      }
    }

    .login-button-container {
      width: 100%;

      @include desktop {
        width: 70%;
      }

      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;

      .login-button {
        .button {
          color: $color1;
          background-color: transparent;
          box-shadow: none;
          border: 2px solid $color1;
          height: 36px;
          border-radius: 18px;
          font-size: $size-small;

          a {
            color: $color1;
            text-decoration: none;
          }
        }
      }

    }
  }
}