.beneficiaries-page{
  .page-title {
    .trade-finance-filter-component {
      flex:1;
      display: flex;
      justify-content: flex-end;
      .search-box {
        flex:1;
      }
    }
  }

  .card-buttons-container {
    flex-shrink: 0;
  }
}