.clauses-page{
  .page-title {
    .trade-finance-filter-component {
      flex:1;
      display: flex;
      justify-content: flex-end;
      .search-box {
        flex:1;
      }
    }
  }

  .field {
    max-width: $field-max-width;
  }
}

.clauses-table {
  .row-actions {
    .action-button {
      margin-bottom: 8px;
    }
  }
}