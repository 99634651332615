.trade-finance-icon-and-title {
  display: flex;
  align-items: center;

  .step-states {
    @include touch {
      display: flex;
      flex-direction: column;
    }
  }

  .card-title {
    font-size: $size-large;
    font-family: "Open Sans Bold";

    .reference-no-label {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-family: $font-family-regular;
      font-weight: normal;
    }

    .reference-no {
      .error {
        font-size: 0.85rem;
        color: $danger;
      }
    }

    .editable-text {

      > span {
        width: 100%;
      }
    }
  }
}

.trade-finance-header-component {
 
  &--title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  &--details {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .columns{
      @include touch {
         display: flex;
        flex-wrap: wrap;

        .column{
            white-space: nowrap;
          max-width: 12.5rem;
          max-height: 11rem;
        }
      }
    }

    .data {
      margin-bottom: 1rem;
      font-size: 0.75rem;
      line-height: 18px;
      
      .caption {
        color: $label-color;
      }
      
      .value {
        font-family: "Open Sans Semibold";
        
        .amount {
          font-size: 1rem;
        }
      }
    }
  }
}

.trade-finance {
  font-size: 1rem;
  font-family: $font-family-regular;

  .divider {
    height: 10px;
    border-radius: 5px;
    margin: 0.5rem 0;
    width: 150px;
    background: currentColor;
  }

  .title {
  
  }

  .partner-details {
    .modifyExistingBeneficiary {
      user-select: none;
      @include desktop {
        margin-top: 26px;
      }
    }

  }

}

.tabs.container .display-flex {
  display: flex !important;
}

li {
  .message-counter {
    background-color: #212121;
    color: $white;
    border-radius: 10px;
    padding: 0 8px;
    margin-left: 6px;
  }
  
  &.is-active .message-counter {
    background-color: $white;
    color: #212121;
  }
}

.new-message-button {
  margin: 20px 200px 0 auto;
  display: flex;
}

.message-file-and-send {
  position: relative;
}

.message-file-and-send button {
  z-index: 10;
  display: flex;
  margin-left: auto;
}

.contact-message-file {
  margin: 0.3rem 0;
}

.table.underwriter-table {
  min-width: 45%;

  td {
    vertical-align: middle;
  }

  .state {
    display: flex;
    justify-content: flex-end;
  }
}

.limitcolumn {
  width: 15%;
  overflow: hidden;
}

.underwritercolumn {
  width: 30%;
  overflow: hidden;
}

.beneficiaryregnumcolumn {
  width: 20%;
  overflow: hidden;
}

.subtypecolumn {
  width: 20%;
  overflow: hidden;
}

.underwriter-card .img-button img, .beneficiary-card .img-button img {
  height: 35px;
}

.underwriter-card .img-button, .beneficiary-card .img-button {
  margin: auto;
}

.underwriter-card .button-label, .beneficiary-card .button-label {
  font-size: 0.75rem !important;
}

.letter-of-credit-identifier {
  padding-left: 20px;
}

.step-content {
  flex: 1;
}

.reference-container {
  
  @include desktop {
    max-width: 50%;
  }
  
  .info {
    font-size: 0.75rem;
    color: #5C5C5C;
  }
}


.claim {
  width: 100%;

  .columns {
    margin-top: 0.5rem;
  }

  .discrepancy {
    .control {
      max-width: initial !important;
    }
  }

  .docs {
    .table {
      background: transparent;

      th {
        border-color: #8b98a8;
      }

      td {
        border: none;
        padding: 3px 0.5rem;

        &:not(:first-child) > * {
          padding: 0 0.5rem;
          border-left: 2px solid $color1;
        }
      }
    }
  }
}

.notification-page {
  > .box {
    padding: 10px;
  }

  .notification-row {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    margin: 16px 0 0 0;
    border: 1px solid #D6D6D6;
    border-radius: 4px;

    .has-grey-background {
      background-color: $color15;
      padding: 13px 10px 10px 10px;
    }

    .notification-row-title {
      padding: 10px;
      line-height: 2rem;
    }

      .trade-finance-notification {
        background: $color15;
        border-radius: 4px;
        padding: 0.5rem;
        font-size: 0.875rem;

        @include touch {
          overflow-wrap: break-word;
        }
      }
  }

  .notification-item {
    color: $primary;
    font-family: "Open Sans Semibold";
    font-size: 1.25rem;
  }

  .actions-column {
    margin-left: auto;
    font-size: 0.75rem;

    .button {
      margin-left: 0.75rem;
    }

    svg {
      margin-left: 4px;
    }
  }

  .action-button {
    display: flex;
    align-items: center;
    color: $label-color;
    cursor: pointer;

    &.rotate-img{
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      margin-right: 6px;
    }

    svg g[id$="-icon-fill"] {
      fill: #ADADAD;
    }
  }
}

.tf-icon {
  width: 90px;
  height: 90px;
  margin-right: 0.5rem;
}

.trade-finance-list-page {

  .card-button {
    .img-button {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .search-result {
    font-size: 1.5rem;
  }

  .draft-filters {
    
    .is-success {
      background: $yellow;
    }

    .drafts-title {
      color: $label-color;
      font-size: 12px;
      font-weight: 600;
      margin-right: 1em;
    }
  }
}

.event-history-component {

  .event-history {
    margin-bottom: 2rem;

    &--title {
      font-weight: bold;
      font-size: $size-medium;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      user-select: none;

      .icon {
        transition: all 0.3s;

        &.is-opened {
          transform: rotate(90deg);
        }
      }
    }

    &--table {
      .table {
        thead {
          tr {

            th:not(:first-child) {
              width: 30%;
            }
          }
        }
      }
    }
  }

}

.form-buttons.buttons {
  justify-content: flex-end;
  padding:0;
  
  .button {
    width:190px;
    border-color: currentColor;
    &:not(:last-child) {
      margin-right: 1rem !important;
      @include touch {
        margin-right: 0rem !important;
      }
    }
    
    &.is-cancel {
      color:$danger;
    }
    &.is-save {
      color:$success;
    }
  }
}

.document-requirement-form {
  &--title {
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.installments-table,
.doc-requirements-table {
  .button {
    color: $label-color;
    border:none;
    padding: 5px;
    background: transparent !important;
    
    svg {
      width: 18px;
      height: 18px;
      margin-right:5px;
      
      * {
        fill: currentColor;
      }
    }
  }
}

.doc-requirements-table {

  tbody {
    tr {
      td:first-child {
        max-width: 400px;
        word-break: break-word;
      }
    }
  }
}

.warning-icon {
  font-size: 20px;
  margin-left: 5px;
  * {
    fill: $danger;
  }
}

.pre-check {
  flex:1;
}

.header .trade-finance-filter-component {
  position: relative;
  bottom: -12px;
}

.trade-finance-filter-component {
  
  .dropdown {
    width: 100%;
    
    .dropdown-trigger {
      font-size: 0.75rem;
      color: #ADADAD;
      display: flex;
      margin-left: auto;
    }
    
    .dropdown-content {
      padding: 0;
    }
  }
  
  .simple-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .search-box {
      @include desktop {
        min-width: 280px;
      }
      
      input {
        border-color: #D6D6D6;
        font-size: 0.75rem;
        height: 30px;
        box-shadow: none;
        
        &:active, &:focus, &.has-value {
          border-color: #ADADAD;
        }
  
        &.has-value + .icon.search-icon {
            background-color: $success;
        }
      }
  
      .icon {
        pointer-events: initial;
        cursor: pointer !important;
        height: 30px;
        width: 32px;
        
        &.search-icon {
          background-color: #D6D6D6;
          color: #ffffff;
          top: 0;
          right: 0;
          border-radius: 0 4px 4px 0;
        }
    
        &.clear-icon {
          right: 32px;
        }
      }
    }

    > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }


  .advanced-filter {
    min-width: 400px;
    @media screen and (min-width: 1320px) {
      min-width: 650px;
      overflow: visible;
    }
      .columns {
        padding: 1rem;

        .column {
          padding: 0 5px;
        }
      }

      .search-button {
        padding: 0 0 0.5rem 0.5rem;
      }

  }

}

.document-list {
  &-item {
    .item-columns-container {
      flex: none !important;
      width: 90%;
    }
  }
}

.error-message {
  font-family: "Open Sans Bold";
  color:$danger;
  font-size: 1rem;
}

#frameAgreementComment {
  [class$="-singleValue"] {
    white-space: pre-wrap;
  }
  div[class$=-control] > div:first-child {
    min-height: 38px;
    @include touch {
      min-height: 3rem;
    }
  }
}

#narrative, #otherCondition {
  height: 100px;
}


#pdfobject {
  height: 80vh;
}