.profile-page {
  min-height: calc(100vh - #{$portal-header-height + $footer-height});

  @include touch {
    min-height: calc(100vh - #{$portal-header-height});
  }

  .profile-container {
    display: flex;
    justify-content: center;

    width: 100%;

    .login-form {
      padding: 0 $container-padding;
      width: 100%;

      input {
        //   background-color: #9eefe6;
      }
    }

    a {
      color: inherit;
    }

    .login-button-container {
      width: 70%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 10px;

      button {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

    }
  }

  $profilePictureSize: 220px;
  .bottom-layout {
    padding-top: 1rem;
    display: flex;

    @include touch {
      flex-wrap: wrap;
      justify-content: center;
    }

    .left {
      width: $profilePictureSize;
      margin-bottom: 1rem;
    }

    .right {
      padding-left: 1rem;
    }

    .portal-button {
      width: 100%;
    }
  }

  .profile-picture-selector {

    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }


    button {
      position: relative;
      margin: 1rem 0;
    }
    .choose-file {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      .file-input {
        width: 100%;
        height: 100%;
      }
    }
    .picture {
      border: 1px solid $color1;
      background: $color14;
      width: $profilePictureSize;
      height: $profilePictureSize;
      .placeholder {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        &.no-content {
          border: 2px dashed $color1;
        }
      }
    }
  }

}

.profile-message {
  margin:10px;
}

.confirm-dialog, .profile-confirm {
  width: 400px !important;
}

.notification-table {
  
  .control {
    text-align: center;
  }

}

.notification-consent {
  font-size: 0.825rem;
  font-family: "Open Sans Semibold";
  color: $input-color;
  
  svg {
    margin-right: 12px;
    align-self: flex-start;
    width: 60px;
  }
}
