.card-list-page {
  max-width: $screen-width;
  margin: auto;
  color: $color1;
}

.list-cards {
  background-color: #ffffff;
  border-radius: 4px;

  &.uploaded-documents {
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 4px 4px 0 0;

    .is-bold {
      color: #212121;
    }

    .column {
      padding:0.25rem;
    }
  }

  .list-card {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &.list-header {
      background: $label-color;
      color: $white;
      border-radius: 4px 4px 0 0;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
    }

    &:not(.list-header) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 50%);
      color: $label-color;

      &:hover {
        box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.25);
      }
    }

    @include touch {
      flex-wrap: wrap;
    }

    &.warn {
      border: 3px solid $warning;
    }

    .columns {
      flex-grow: 1;

      @include touch{
        display: flex;
      }
    }

    .card-buttons-container {
      display: flex;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0;

      &.horizontal-buttons {
        flex-direction: row;

        .card-button {
          margin-right: 1.25rem;
          cursor: pointer;
          font-size: 0.6rem;
          font-family: "Open Sans";
          color: $label-color;

          svg g[id$="-icon-fill"] {
            fill: $label-color;
          }

          &:hover {
            color: $primary;
            text-decoration: underline;

            svg g[id$="-icon-fill"] {
              fill: $primary;
            }
          }
          
          &:last-child {
            margin-right: 0;
          }

          &.disabled {
            pointer-events: none;
          }
        }
      }

      @include touch {
        flex: 1;
      }

      @include desktop {
        padding-right: 0;
      }

      @include text2();

      a {
        color: $color1;
        text-decoration: none;
      }

      .card-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include touch {
          //flex-direction: row;
          flex: 1;
          //flex-flow: row-reverse;
          .button-label {
            //margin-left: 1rem;
          }
        }
        @include touch {
          img {
            width: 50px;
            height: 50px;
            margin: 0.5rem 0.5rem 0.5rem 0;
          }
        }
      }

    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      font-size: 0.75rem;

      @include touch {
        max-width: 100%;
      }

      .card-title-container {
        display: flex;
        flex-wrap: nowrap;

        .card-title {
          text-transform: none;
          @include text1();
          @include touch {
            order: 2;
          }
        }

        .warning {
          padding-left: 70px;
        }
      }

      .icon {
        color: $active-color;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .amount {
        font-size: 1rem;
        line-height: 0.75rem;
      }

      .amount, .beneficiary {
        font-family: "Open Sans Semibold";
        color: #212121;
      }

      .currency {
        font-family: "Open Sans Semibold";
      }

      .state {
        color: $color13;
        border: 1px solid $color13;
        border-radius: 12px;
        display: initial;
        padding: 0 10px;
        white-space: nowrap;
        @include touch {
          white-space: pre-wrap;
        }
      }
    }

  }

  .list-footer {
    height: 60px;
    background: $color15;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-radius: 0 0 4px 4px;
  }
}