
.page-header {
  padding-top: 30px;
  @include touch {
      position: relative;
  }

  .portal-buttons {
    margin-left: auto;
    @include touch{
      overflow: scroll;
    }
  }
  
  + div {
    margin-top: 1rem !important;
  }

  .is-ongoing {
    position: absolute;
    right: 0;
    bottom: -1rem;
  }
}

.page-title {
  display: flex;
  align-items: center;
  color: #2F333E;
  font-size: 2.5rem;
  font-family: "Open Sans Semibold";
  margin-right: 8px;
  
  > *:not(:last-child) {
    margin-right: 1rem;
  }

  @include touch {
    margin-bottom: 20px;
  }
}

.page-subtitle {
  color: $button-focus-color;
  font-size: 1.5rem;
  font-family: "Open Sans Semibold";
  line-height: 1;
}

.show-more-button {
  padding:0 16px;

  button {
    width: 50%;
    display: flex;
    justify-content: center;
    font-family: "Open Sans Semibold";
    color: $label-color;
    margin: 5px auto;

    &:focus {
      border-color: $primary;
    }
  }

}
/* Common hover states */

.has-red-hover:hover {
  color: $danger !important;
  
  svg g[id$="-icon-fill"] {
    fill: $danger !important;
  }
}

.has-green-hover:hover {
  color: $color13 !important;
  
  svg g[id$="-icon-fill"] {
    fill: $color13 !important;
  }
}