.bm-overlay,.bm-menu-wrap {
  top:0;
  left:0;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  z-index: 10000;
  @include touch {
    width: 30px;
    height: 25px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $color1;
}

.bm-burger-bar {
  height: 10% !important;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: $color1;
  padding: 2.5em 1em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $color1;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.slider-menu {
  .menu-item {
    margin-bottom: 1rem;

    &.is-active {
      .menu-header {
        background: $active-color;
        margin:0 -1rem;
        padding-left:1rem;
        img:not(.active-icon) {
          display: none;
        }
        img.active-icon {
          display: inline;
        }
      }
    }

    .menu-header {
      display: flex;
      img.active-icon {
        display: none;
      }

      &.bottom-margin {
        margin-bottom: 1rem;
      }

      div.empty {
        width: 30px;
      }
    }

    .menu-item-label {
      font-family: $font-family-bold;
      font-size: $size-medium;
      text-transform: uppercase;
      color: $white;

      &.is-submenu {
        cursor: pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        font-family: $font-family-regular;
        font-size: $size-normal;
      }
    }

    &.lang-selector {
      padding:1rem 0;
      border-top: 2px solid $active-color;
      border-bottom: 2px solid $active-color;

      cursor: pointer;
      .is-submenu {
        margin: 0 -1rem;
        padding-left: calc(30px + 1rem);
      }

      .menu-header {
        padding-bottom: 1rem;
        .menu-item-label {
          span { //Lefele nyíl
            margin-left: 3px;
          }
        }
      }


      .is-active {
        font-family: $font-family-bold;
        background: $active-color;
      }
    }
  }
}