.list-card-comp {
  $icon-border:2px;
  $icon-size:70px;
  max-width: 348px;
  margin: auto;

  &:not(:first-child) {
    margin-top: $icon-size * 0.75;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  padding: 0 1rem 1rem 1rem;

  border: 2px solid $white;

  &-icon {
    height: $icon-size / 2;
    position: relative;
    display: flex;
    justify-content: center;
    img {
      position: absolute;
      width: $icon-size;
      height: $icon-size;
      top:-$icon-size / 2;
      border:2px solid $color1;
      border-radius: 50%;
    }

  }

  &-title {
    font-size: $size-large;
    font-family: $font-family-bold;
    display: flex;
    justify-content: center;
  }

  &-state {
    display: flex;
    justify-content: center;
    margin:0.5rem 0;
  }

  &-body {

  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}