.underwriters-config-page {
  table {
    
    .limit-notification {
      font-family: "Open Sans Semibold";
      font-size: 0.75rem;
      color: $title-color;
    }

    thead {
      tr {
        th {
          &.number-col {
            width: 100px;
          }
          
          &.field-col {
            width: 130px;
          }
        }
      }
    }
  }
  
  .portal-buttons button {
    display:flex;
    margin-left: auto;
  }
}

#limit-notification-dropdown {
  padding: 0;
  top: -49px;
  cursor: pointer;
  
  .dropdown-content {
    padding: 0;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.32);
    position: relative;

    .dropdown-item:hover {
      background: $white;
    }
  
    &:after {
      border-style: solid;
      border-color: white transparent;
      border-width: 12px 13px 0 13px;
      bottom: 0;
      transform: translateY(100%);
      left: 10%;
      content: '';
      position: absolute;
    }

    &:before {
      border-style: solid;
      border-color: #999 transparent;
      border-width: 11px 13px 0 13px;
      bottom: 0;
      transform: translateY(100%);
      left: 10%;
      content:'';
      position: absolute;
      
    }
  }
}

.number-spinner {
  width: 60px;
  height: 26px;

  justify-content: center;
  
  input.is-small {
    text-align: right;
    border-radius: 4px 0 0 4px;
    height: inherit;
  }
  
  .spinner-button {
    height: 13px;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ADADAD;
    color: $white !important;
    cursor: pointer;
    user-select: none;
    
    &.is-plus {
      border-radius: 0 4px 0 0;
    }
    &.is-minus {
      border-radius: 0 0 4px 0;
    }
  }
}