$modal-border-width: 3px;

.modal {
  z-index: 1000;

  .modal-card {
    overflow: visible;
    @include touch {
      max-width: 90%
    }
    .modal-card-title {
      font-family: "Open Sans Semibold";
    }
    
    .modal-card-body {
      min-height: 40px;
    }

    .close-button {
      position: absolute;
      cursor: pointer;
      top: -16px;
      right: -16px;
      border-radius: 50%;
      background-color: $color1;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.75);
    }
  
  }

  &.warning .modal-card-body {
    border-color: $warning;
  }

  .modal-card-foot {
    .modal-buttons {
      display: flex;
      flex:1;
      @include desktop {
        justify-content: flex-end;
      }
      align-items: center;
      
      .button,a {
        &.margin-left-auto {
          margin-left: auto !important;
        }

        &:not(:first-child) {
          margin-left:1rem;
          &.on-modal{
            min-width:auto;
          }
        }
      }
    }
  }

  .modal-message {
    padding: 1rem;
  }
}

.modal-window {

  &.modal-card {
    @include desktop {
      min-width: 960px;
      width: $screen-width - 20px;
    }
    @include touch {
      display: flex;
    }
  }

  header {
    box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.08);
    .close-icon {
      cursor:pointer;
    }
  }

  section {

  }

  footer  {
    box-shadow:0 0 8px 0 rgba(0, 0, 0, 0.32);
    @include touch {
      display: block;
      overflow: scroll;
    }
  }

}